import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import QuestionCard from "./QuestionCard";
const ResumeQuestions = () => {
  const [resume, setResume] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [conversationText, setConversationText] = useState([]);
  const [audioKeys, setAudioKeys] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [blobURLs, setBlobURLs] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [responseTexts, setResponseTexts] = useState([]);
  const handleResponseTextChange = (index, responseText) => {
    const newResponseTexts = [...responseTexts];
    newResponseTexts[index] = responseText;
    setResponseTexts(newResponseTexts);
  };
  const handleConversationTextChange = newItems => {
    setConversationText([...conversationText, ...newItems]);
    console.log([...conversationText, ...newItems]);
  };
  const handleBlobURLChange = (index, blobURL) => {
    const newBlobURLs = [...blobURLs];
    newBlobURLs[index] = blobURL;
    setBlobURLs(newBlobURLs);
  };
  const handleFeedbackChange = (index, feedback) => {
    const newFeedbacks = [...feedbacks];
    newFeedbacks[index] = feedback;
    setFeedbacks(newFeedbacks);
  };
  const handleAudioKeyChange = (index, audioKey) => {
    const newAudioKeys = [...audioKeys];
    newAudioKeys[index] = audioKey;
    setAudioKeys(newAudioKeys);
  };
  useEffect(() => {
    setCurrentQuestionIndex(0);
  }, [questions]);
  const handlePrevQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    let fullQuery = `Act as an interviewer for the following job posting "${jobDescription}", along with the job posting here is the interviewees resume "${resume}", please generate ten questions specific to the job posting and interviewees resume, the questions should ask about the interviewees experience from their resume as well as questions that would reveal if the interviewee is a good fit for the company. Please format your response as a JSON with an array of ten strings, with each string containing a question. For example:

    {
      "questions": [
        //List of questions for the specific job posting and resume
      ]
    }
    
    If there is nothing to say about a particular field, simply leave the array empty. Please make sure to use the exact field names provided (questions), and include double quotes around all strings. Do not include any additional text in your response
    `;
    const request = { role: "user", content: fullQuery };
    try {
      await API.post("chatEndpoints", "/chat/completion", {
        body: [...conversationText, request],
        headers: { "Content-Type": "application/json" }
      }).then(res => {
        // handle response
        const result = res.query;
        console.log(res);
        handleConversationTextChange(request, result);
        const json = JSON.parse(result.content);
        const newQuestions = json.questions.map(question => question);
        setQuestions(newQuestions);
        setBlobURLs(new Array(newQuestions.length).fill(""));
        setAudioKeys(new Array(newQuestions.length).fill(""));
        setResponseTexts(new Array(newQuestions.length).fill(""));
        setFeedbacks(new Array(newQuestions.length).fill(""));
        setLoading(false);
        console.log(newQuestions);
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      // handle error
    }
  };
  const questionCards = questions.map((question, index) => {
    return (
      <QuestionCard
        key={index}
        question={question}
        index={index}
        conversation={conversationText}
        blobURL={blobURLs[index]}
        audioKey={audioKeys[index]}
        responseText={responseTexts[index]}
        feedback={feedbacks[index]}
        onBlobURLChange={blobURL => handleBlobURLChange(index, blobURL)}
        onAudioKeyChange={audioKey => handleAudioKeyChange(index, audioKey)}
        onResponseTextChange={responseText =>
          handleResponseTextChange(index, responseText)
        }
        onConversationTextChange={handleConversationTextChange}
        onFeedbackChange={feedback => handleFeedbackChange(index, feedback)}
        next={handleNextQuestion}
        prev={handlePrevQuestion}
      />
    );
  });
  const boxStyle = {
    display: "flex",
    padding: { xs: 2, sm: 4, md: 6, lg: 8 },
    alignItems: "center",
    flexDirection: "column",
    minHeight: "100%"
  };

  const headerStyle = {
    display: "flex",
    textAlign: "center",
    fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
    fontWeight: 550,
    fontSize: "30px",
    fontStyle: "normal",
    letterSpacing: "0.25px",
    lineHeight: "30px",
    color: "#000000",
    zIndex: "2",
    marginBottom: 2
  };
  const additionalText = {
    display: "flex",
    alignContent: "left",
    fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
    fontWeight: 450,
    fontSize: "18px",
    letterSpacing: "0.25px",
    fontStyle: "normal",
    lineHeight: "30px",
    zIndex: "2",
    color: "#000000",
    marginBottom: 4
  };
  const buttonStyle = {
    fontSize: "1.2rem",
    fontWeight: 600,
    fontFamily: "'Inter', sans-serif",
    padding: "0.7rem",
    lineHeight: "22px",
    paddingLeft: "1rem",
    textTransform: "none",
    paddingRight: "1rem",
    letterSpacing: "1px",
    color: "#000000",
    borderColor: "#3854e8",
    marginBottom: 2,
    textColor: "#00000",
    borderRadius: "20px",
    zIndex: "2",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)"
    }
  };
  const inputStyle = {
    fontFamily: "'Inter', sans-serif",
    marginBottom: 2,
    zIndex: "2",
    "& .MuiOutlinedInput-root": {
      fontSize: "1.2rem",
      fontWeight: 800,
      lineHeight: "22px",
      letterSpacing: "1px",
      borderRadius: "20px",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#3854e8"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#3854e8"
      }
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "1.2rem",
      fontWeight: 545,
      letterSpacing: "1px",

      "&.Mui-focused": {
        color: "#000000"
      }
    }
  };
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: 0,
          zIndex: "-1",
          left: 0,
          width: "100%",
          height: "325px",
          background: "linear-gradient(90deg, #ccdefb 0%, #808cde 100%)",
          opacity: 1,
          transform: "skewY(-9deg)",
          transformOrigin: "bottom left"
        }}
      />
      <Container sx={boxStyle}>
        <Typography sx={headerStyle} variant="h5" gutterBottom>
          Resume Based Interview Questions
        </Typography>
        <Typography sx={additionalText} variant="body1" gutterBottom>
          Paste your resume and the job description of the position you're
          applying for to get personalized interview questions.
        </Typography>
        <Grid sx={inputStyle} container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Paste your resume"
              multiline
              rows={10}
              fullWidth
              variant="outlined"
              value={resume}
              onChange={e => setResume(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Paste job description"
              multiline
              rows={10}
              fullWidth
              variant="outlined"
              value={jobDescription}
              onChange={e => setJobDescription(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button
          sx={buttonStyle}
          variant="outlined"
          onClick={handleSubmit}
          disabled={!resume || !jobDescription}
        >
          Generate Questions
        </Button>
        {loading ? (
          <CircularProgress />
        ) : questions.length > 0 ? (
          questionCards[currentQuestionIndex]
        ) : (
          <div />
        )}
      </Container>
    </div>
  );
};

export default ResumeQuestions;
