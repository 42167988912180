import React from "react";
import { Typography, Box } from "@mui/material";
export default function Feedback(props) {
  const { positive, constructive, improvement } = JSON.parse(props.feedback);

  const positiveFeedback =
    positive && positive.length !== 0 ? (
      positive.map(item => (
        <li
          style={{
            listStyleType: "lower-roman",
            marginBottom: 8,
            lineHeight: 1.6
          }}
          key={item}
        >
          {item}
        </li>
      ))
    ) : (
      <li style={{ listStyleType: "none" }} key="positive">
        None
      </li>
    );
  const constructiveFeedback =
    constructive && constructive.length !== 0 ? (
      constructive.map(item => (
        <li
          style={{
            listStyleType: "lower-roman",
            marginBottom: 8,
            lineHeight: 1.6
          }}
          key={item}
        >
          {item}
        </li>
      ))
    ) : (
      <li style={{ listStyleType: "none" }} key="constructive">
        None
      </li>
    );
  const improvementFeedback =
    improvement && improvement.length !== 0 ? (
      improvement.map(item => (
        <li
          style={{
            listStyleType: "lower-roman",
            marginBottom: 8,
            lineHeight: 1.6
          }}
          key={item}
        >
          {item}
        </li>
      ))
    ) : (
      <li style={{ listStyleType: "none" }} key="improvement">
        None
      </li>
    );
  return (
    <Box
      variant="body1"
      sx={{
        backgroundColor: "#f5f5f5",
        padding: "10px",
        borderRadius: "5px",
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
        whiteSpace: "pre-wrap",
        textAlign: "left"
      }}
    >
      <Typography align="center">Positive Feedback:</Typography>
      <ul>{positiveFeedback}</ul>
      <Typography align="center">Constructive Feedback:</Typography>
      <ul>{constructiveFeedback}</ul>
      <Typography align="center">Areas for improvement:</Typography>
      <ul>{improvementFeedback}</ul>
    </Box>
  );
}
