/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      name
      subscriptionType
      stripeSubscriptionID
      stripeUserID
      dailyQueries
      questions {
        items {
          id
          questionText
          qID
          feedback
          responseText
          conversation {
            role
            content
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyAnsweredQuestions {
        items {
          id
          date
          answeredQuestionsCount
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        name
        subscriptionType
        stripeSubscriptionID
        stripeUserID
        dailyQueries
        questions {
          nextToken
        }
        dailyAnsweredQuestions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      questionText
      qID
      feedback
      responseText
      conversation {
        role
        content
      }
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questionText
        qID
        feedback
        responseText
        conversation {
          role
          content
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDailyAnsweredQuestions = /* GraphQL */ `
  query GetDailyAnsweredQuestions($id: ID!) {
    getDailyAnsweredQuestions(id: $id) {
      id
      date
      answeredQuestionsCount
      userID
      createdAt
      updatedAt
    }
  }
`;
export const listDailyAnsweredQuestions = /* GraphQL */ `
  query ListDailyAnsweredQuestions(
    $filter: ModelDailyAnsweredQuestionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyAnsweredQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        answeredQuestionsCount
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
