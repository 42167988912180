import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress
} from "@mui/material";
import QuestionCard from "./QuestionCard";

const Question = () => {
  const [jobPosition, setJobPosition] = useState("");
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [conversationText, setConversationText] = useState([]);
  const [blobURLs, setBlobURLs] = useState([]);
  const [audioKeys, setAudioKeys] = useState([]);
  const [responseTexts, setResponseTexts] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setCurrentQuestionIndex(0);
  }, [questions]);
  const handlePrevQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };
  const handleBlobURLChange = (index, blobURL) => {
    const newBlobURLs = [...blobURLs];
    newBlobURLs[index] = blobURL;
    setBlobURLs(newBlobURLs);
  };

  const handleAudioKeyChange = (index, audioKey) => {
    const newAudioKeys = [...audioKeys];
    newAudioKeys[index] = audioKey;
    setAudioKeys(newAudioKeys);
  };
  const handleConversationTextChange = newConversation => {
    console.log("stored conversation");
    setConversationText([...conversationText, newConversation]);
    console.log(conversationText);
  };
  const handleResponseTextChange = (index, responseText) => {
    console.log("response text changed");
    const newResponseTexts = [...responseTexts];
    newResponseTexts[index] = responseText;
    setResponseTexts(newResponseTexts);
  };

  const handleFeedbackChange = (index, feedback) => {
    const newFeedbacks = [...feedbacks];
    newFeedbacks[index] = feedback;
    setFeedbacks(newFeedbacks);
  };
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    let fullQuery = `As an interviewer for a ${jobPosition} position, please generate ten questions an interviewee should expect in an interview. Please format your response as a JSON with an array of ten strings, with each string containing a question. For example:

    {
      "questions": [
        //List of questions for the specific job position
      ]
    }
    
    If there is nothing to say about a particular field, simply leave the array empty. Please make sure to use the exact field names provided (questions), and include double quotes around all strings. Do not include any additional text in your response
    `;
    const request = [{ role: "user", content: fullQuery }];
    try {
      await API.post("chatEndpoints", "/chat/completion", {
        body: request,
        headers: { "Content-Type": "application/json" }
      }).then(res => {
        // handle response
        const result = res.query;
        handleConversationTextChange(request, result);
        const json = JSON.parse(result.content);
        console.log(json);
        const newQuestions = json.questions.map(question => question);
        setQuestions(newQuestions);
        setBlobURLs(new Array(newQuestions.length).fill(""));
        setAudioKeys(new Array(newQuestions.length).fill(""));
        setResponseTexts(new Array(newQuestions.length).fill(""));
        setFeedbacks(new Array(newQuestions.length).fill(""));
        setLoading(false);
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      // handle error
    }
  };

  const boxStyle = {
    backgroundColor: "#EDEDED",
    display: "flex",
    padding: { xs: 2, sm: 4, md: 6, lg: 8 },
    alignItems: "center",
    flexDirection: "column",
    minHeight: "100%"
  };

  const headerStyle = {
    display: "flex",
    textAlign: "center",
    fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
    fontWeight: 550,
    fontSize: "30px",
    fontStyle: "normal",
    letterSpacing: "0.25px",
    lineHeight: "30px",
    color: "#000000",
    zIndex: "2",
    marginBottom: 2
  };
  const additionalText = {
    display: "flex",
    alignContent: "left",
    fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
    fontWeight: 450,
    fontSize: "18px",
    letterSpacing: "0.25px",
    fontStyle: "normal",
    lineHeight: "30px",
    zIndex: "2",
    color: "#000000",
    marginBottom: 4
  };
  const buttonStyle = {
    fontSize: "1.2rem",
    fontWeight: 600,
    fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
    padding: "0.7rem",
    lineHeight: "22px",
    paddingLeft: "1rem",
    textTransform: "none",
    paddingRight: "1rem",
    letterSpacing: "1px",
    color: "#000000",
    borderColor: "#3854e8",
    marginBottom: 2,
    textColor: "#00000",
    borderRadius: "20px",
    zIndex: "2",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)"
    }
  };
  const inputStyle = {
    fontFamily: "'nunito', sans-serif",
    marginBottom: 2,
    zIndex: "2",
    "& .MuiOutlinedInput-root": {
      fontSize: "1.2rem",
      fontWeight: 800,
      lineHeight: "22px",
      letterSpacing: "1px",
      borderRadius: "20px",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#3854e8"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#3854e8"
      }
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "1.2rem",
      fontWeight: 545,
      letterSpacing: "1px",

      "&.Mui-focused": {
        color: "#000000"
      }
    }
  };
  const questionCards = questions.map((question, index) => {
    return (
      <QuestionCard
        key={index}
        question={question}
        index={index}
        conversation={conversationText}
        blobURL={blobURLs[index]}
        audioKey={audioKeys[index]}
        responseText={responseTexts[index]}
        feedback={feedbacks[index]}
        onBlobURLChange={blobURL => handleBlobURLChange(index, blobURL)}
        onAudioKeyChange={audioKey => handleAudioKeyChange(index, audioKey)}
        onResponseTextChange={responseText =>
          handleResponseTextChange(index, responseText)
        }
        onConversationTextChange={handleConversationTextChange}
        onFeedbackChange={feedback => handleFeedbackChange(index, feedback)}
        next={handleNextQuestion}
        prev={handlePrevQuestion}
      />
    );
  });
  return (
    <Box component="form" sx={boxStyle} noValidate autoComplete="off">
      <Typography sx={headerStyle} variant="h5">
        Let us help you prepare for your next interview!
      </Typography>
      <Typography sx={additionalText}>
        What is the job position you are preparing for? We will generate
        interview questions for you to practice.
      </Typography>
      <TextField
        id="job-position"
        label="Job Position"
        variant="outlined"
        sx={inputStyle}
        value={jobPosition}
        onChange={e => setJobPosition(e.target.value)}
      />
      <Button
        variant="outlined"
        onClick={handleSubmit}
        disabled={!jobPosition || loading}
        sx={buttonStyle}
      >
        Submit
      </Button>
      {loading ? (
        <CircularProgress sx={{ zIndex: 2 }} />
      ) : questions.length > 0 ? (
        questionCards[currentQuestionIndex]
      ) : (
        <div />
      )}
    </Box>
  );
};

export default Question;
