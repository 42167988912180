import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  CircularProgress
} from "@mui/material";
import { API } from "aws-amplify";
import React, { useState, useEffect, useContext } from "react";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { stripePublishableKey } from "../stripeConfig";
import UserContext from "./UserContext";

function ActivatePayments(props) {
  const [open, setOpen] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [error, setError] = useState(false);
  const { user } = useContext(UserContext);
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(stripePublishableKey)
  );
  const paymentElementOptions = {
    clientSecret
  };
  useEffect(() => {
    async function fetchClientSecret() {
      setLoading(true);
      const name = user.attributes.name;
      const email = user.attributes.email;
      try {
        const response = await API.post(
          "chatEndpoints",
          "/chat/create_payment",
          {
            body: { email: email, name: name },
            headers: {
              "Content-Type": "application/json"
            }
          }
        );
        console.log(response);
        const { clientId, clientSecret, subscriptionId } = await response;
        setClientId(clientId);
        setClientSecret(clientSecret);
        setSubscriptionId(subscriptionId);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }

    !clientSecret && !subscriptionId && fetchClientSecret();
  }, []);
  //only want to create an intent when a user selects upgrade for the first time
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = bool => {
    setOpen(false);
    if (bool) setConfirmation(true);
    else setError(true);
  };
  const boxStyle = {
    backgroundColor: "#EDEDED",
    display: "flex",
    padding: { xs: 2, sm: 4, md: 6, lg: 8 },
    alignItems: "center",
    flexDirection: "column",
    minHeight: "100%"
  };

  const headerStyle = {
    display: "flex",
    textAlign: "center",
    fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
    fontWeight: 550,
    fontSize: "30px",
    fontStyle: "normal",
    letterSpacing: "0.25px",
    lineHeight: "30px",
    color: "#000000",
    zIndex: "2",
    marginBottom: 2
  };
  const additionalText = {
    display: "flex",
    alignContent: "left",
    fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
    fontWeight: 450,
    fontSize: "22px",
    lineHeight: "28px",
    letterSpacing: "0.25px",
    fontStyle: "normal",
    zIndex: "2",
    color: "#000000",
    marginBottom: 2
  };
  const buttonStyle = {
    fontSize: "1.2rem",
    fontWeight: 600,
    fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
    padding: "0.7rem",
    lineHeight: "22px",
    paddingLeft: "1rem",
    textTransform: "none",
    paddingRight: "1rem",
    letterSpacing: "1px",
    color: "#000000",
    borderColor: "#3854e8",
    marginBottom: 2,
    textColor: "#00000",
    borderRadius: "20px",
    zIndex: "2",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)"
    }
  };
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", width: "100%", flex: 2 }}
    >
      <Box sx={{ maxWidth: "60vw" }}>
        <Box>
          <Box
            sx={{
              backgroundColor: "white",
              pt: 2,
              pb: 4,
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  flexBasis: 0,
                  flexGrow: 1
                }}
              >
                <Typography
                  variant="h4"
                  align="left"
                  sx={{
                    fontWeight: "bold",
                    lineHeight: "32px",
                    mb: 2
                  }}
                >
                  Upgrade Your Account
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  sx={{
                    ...additionalText
                  }}
                >
                  Experience more benefits with our premium membership.
                </Typography>

                <Typography
                  align="left"
                  sx={{
                    fontFamily:
                      "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif"
                  }}
                  color="textSecondary"
                >
                  Unlimited feedback per day
                </Typography>
                <Typography
                  align="left"
                  sx={{
                    fontFamily:
                      "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif"
                  }}
                  color="textSecondary"
                >
                  Save questions to practice them later
                </Typography>
                <Typography
                  align="left"
                  sx={{
                    fontFamily:
                      "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif"
                  }}
                  color="textSecondary"
                >
                  Greater chances of landing your dream job
                </Typography>
              </Box>
              <Box
                sx={{
                  display: { xs: "none", md: "none", lg: "flex" },
                  alignItems: "flex-end"
                }}
              ></Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ display: "flex" }}>
              <Button
                variant="outlined"
                color="primary"
                sx={buttonStyle}
                onClick={handleClickOpen}
                endIcon={<ArrowForwardIcon />}
                disabled={open}
              >
                Upgrade
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      {clientSecret && (
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <Elements stripe={stripePromise} options={paymentElementOptions}>
              <CheckoutForm
                subscriptionId={subscriptionId}
                customerId={clientId}
                handleClose={handleClose}
              />
            </Elements>
          </DialogContent>
        </Dialog>
      )}
      <Dialog open={confirmation} onClose={() => setConfirmation(false)}>
        <DialogTitle>Subscription Started</DialogTitle>
        <DialogContent>
          <DialogContentText>Your subscription has begun.</DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog open={error} onClose={() => setError(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>
            There was an error processing your payment, make sure you entered
            your information correctly.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ActivatePayments;
