import React, { useState } from "react";
import { API } from "aws-amplify";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress
} from "@mui/material";
import QuestionCard from "./QuestionCard";
export default function QuestionTailored() {
  const [jobDescription, setJobDescription] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [conversationText, setConversationText] = useState([]);
  const [blobURLs, setBlobURLs] = useState([]);
  const [audioKeys, setAudioKeys] = useState([]);
  const [responseTexts, setResponseTexts] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const boxStyle = {
    display: "flex",
    padding: { xs: 2, sm: 4, md: 6, lg: 8 },
    alignItems: "center",
    flexDirection: "column",
    minHeight: "100%"
  };

  const headerStyle = {
    display: "flex",
    textAlign: "center",
    fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
    fontWeight: 550,
    fontSize: "30px",
    fontStyle: "normal",
    letterSpacing: "0.25px",
    lineHeight: "30px",
    color: "#000000",
    zIndex: "2",
    marginBottom: 2
  };
  const additionalText = {
    display: "flex",
    alignContent: "left",
    fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
    fontWeight: 450,
    fontSize: "18px",
    letterSpacing: "0.25px",
    fontStyle: "normal",
    lineHeight: "30px",
    zIndex: "2",
    color: "#000000",
    marginBottom: 4
  };
  const buttonStyle = {
    fontSize: "1.2rem",
    fontWeight: 600,
    fontFamily: "'Inter', sans-serif",
    padding: "0.7rem",
    lineHeight: "22px",
    paddingLeft: "1rem",
    textTransform: "none",
    paddingRight: "1rem",
    letterSpacing: "1px",
    color: "#000000",
    borderColor: "#3854e8",
    marginBottom: 2,
    textColor: "#00000",
    borderRadius: "20px",
    zIndex: "2",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)"
    }
  };
  const inputStyle = {
    fontFamily: "'Inter', sans-serif",
    marginBottom: 2,
    zIndex: "2",
    "& .MuiOutlinedInput-root": {
      fontSize: "1.2rem",
      fontWeight: 800,
      lineHeight: "22px",
      letterSpacing: "1px",
      borderRadius: "20px",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#3854e8"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#3854e8"
      }
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "1.2rem",
      fontWeight: 545,
      letterSpacing: "1px",

      "&.Mui-focused": {
        color: "#000000"
      }
    }
  };
  const handleInputChange = event => {
    setJobDescription(event.target.value);
  };
  const handlePrevQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };
  const handleBlobURLChange = (index, blobURL) => {
    const newBlobURLs = [...blobURLs];
    newBlobURLs[index] = blobURL;
    setBlobURLs(newBlobURLs);
  };

  const handleAudioKeyChange = (index, audioKey) => {
    const newAudioKeys = [...audioKeys];
    newAudioKeys[index] = audioKey;
    setAudioKeys(newAudioKeys);
  };

  const handleResponseTextChange = (index, responseText) => {
    const newResponseTexts = [...responseTexts];
    newResponseTexts[index] = responseText;
    setResponseTexts(newResponseTexts);
  };

  const handleFeedbackChange = (index, feedback) => {
    const newFeedbacks = [...feedbacks];
    newFeedbacks[index] = feedback;
    setFeedbacks(newFeedbacks);
  };
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    let fullQuery = `Act as an interviewer for the following job posting ${jobDescription}, please generate ten questions an interviewee should expect in an interview for the position. Please format your response as a JSON with an array of ten strings, with each string containing a question. For example:

    {
      "questions": [
        //List of questions for the specific job posting
      ]
    }
    
    If there is nothing to say about a particular field, simply leave the array empty. Please make sure to use the exact field names provided (questions), and include double quotes around all strings. Do not include any additional text in your response
    `;
    const request = [{ role: "user", content: fullQuery }];
    try {
      await API.post("chatEndpoints", "/chat/completion", {
        body: request,
        headers: { "Content-Type": "application/json" }
      }).then(res => {
        // handle response
        const result = res.query;
        setConversationText(request, result);
        const json = JSON.parse(result.content);
        const newQuestions = json.questions.map(question => question);
        setQuestions(newQuestions);
        setBlobURLs(new Array(newQuestions.length).fill(""));
        setAudioKeys(new Array(newQuestions.length).fill(""));
        setResponseTexts(new Array(newQuestions.length).fill(""));
        setFeedbacks(new Array(newQuestions.length).fill(""));
        setLoading(false);
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      // handle error
    }
  };
  const questionCards = questions.map((question, index) => {
    return (
      <QuestionCard
        key={index}
        question={question}
        index={index}
        conversation={conversationText}
        blobURL={blobURLs[index]}
        audioKey={audioKeys[index]}
        responseText={responseTexts[index]}
        feedback={feedbacks[index]}
        onBlobURLChange={blobURL => handleBlobURLChange(index, blobURL)}
        onAudioKeyChange={audioKey => handleAudioKeyChange(index, audioKey)}
        onResponseTextChange={responseText =>
          handleResponseTextChange(index, responseText)
        }
        onFeedbackChange={feedback => handleFeedbackChange(index, feedback)}
        next={handleNextQuestion}
        prev={handlePrevQuestion}
      />
    );
  });
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: 0,
          zIndex: "-1",
          left: 0,
          width: "100%",
          height: "325px",
          background: "linear-gradient(90deg, #ccdefb 0%, #808cde 100%)",
          opacity: 1,
          transform: "skewY(-9deg)",
          transformOrigin: "bottom left"
        }}
      />
      <Box component="form" sx={boxStyle} noValidate autoComplete="off">
        <Typography sx={headerStyle} variant="h5">
          Generate Tailored Interview Questions Based on Your Job Description
        </Typography>
        <Typography sx={additionalText} variant="body1">
          Simply paste in the job description and let us do the rest. We will
          analyze the job requirements to generate a list of relevant interview
          questions tailored to the position. We make sure you'll be fully
          prepared for your next interview and ready to impress your potential
          employer.
        </Typography>
        <TextField
          id="job-description"
          label="Job Description"
          multiline
          rows={4}
          sx={inputStyle}
          variant="outlined"
          fullWidth
          value={jobDescription}
          onChange={handleInputChange}
        />
        <Button
          variant="outlined"
          sx={buttonStyle}
          onClick={handleSubmit}
          disabled={!jobDescription || isLoading}
        >
          Generate Questions
        </Button>
        {isLoading ? (
          <CircularProgress />
        ) : questions.length > 0 ? (
          questionCards[currentQuestionIndex]
        ) : (
          <div />
        )}
      </Box>
    </div>
  );
}
