import React, { useState, useEffect, useContext } from "react";
import { Amplify, Auth, API, graphqlOperation, Hub } from "aws-amplify";
import { getUser } from "../graphql/queries";
import SavedQuestion from "./SavedQuestion";
import { CircularProgress, Grid, Button, Box, Typography } from "@mui/material";
import { updateQuestion } from "../graphql/mutations";
import { createUser, updateUser } from "../graphql/mutations";
import UserContext from "./UserContext";
import ProgressStatistics from "./ProgressStatitstics";
import HorizontalScrollCalendar from "./HorizontalScrollCalendar";
import DailyQueries from "./DailyQueries";
import ProfileCard from "./ProfileCard";

export default function Profile() {
  const [name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [questions, setQuestions] = useState([]);
  const [conversationText, setConversationText] = useState([]);
  const [blobURLs, setBlobURLs] = useState([]);
  const [audioKeys, setAudioKeys] = useState([]);
  const [responseTexts, setResponseTexts] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subscriptionID, setSubscriptionID] = useState("");
  const [dailyQueries, setDailyQueries] = useState(null);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [questionsToday, setQuestionsToday] = useState(0);
  const [saved, setSaved] = useState([]);
  const { user, subscriptionType, setSubscriptionType } = useContext(
    UserContext
  );

  const [userId, setUserId] = useState(user ? user.username : null);
  const handleBlobURLChange = (index, blobURL) => {
    const newBlobURLs = [...blobURLs];
    newBlobURLs[index] = blobURL;
    setBlobURLs(newBlobURLs);
  };
  const handleChangeSave = (index, save) => {
    const newSaves = [...saved];
    newSaves[index] = save;
    setSaved(newSaves);
  };
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  const handleAudioKeyChange = (index, audioKey) => {
    const newAudioKeys = [...audioKeys];
    newAudioKeys[index] = audioKey;
    setAudioKeys(newAudioKeys);
  };
  const handleConversationTextChange = newItems => {
    setConversationText([...conversationText, ...newItems]);
  };
  const handleResponseTextChange = (index, responseText) => {
    const newResponseTexts = [...responseTexts];
    newResponseTexts[index] = responseText;
    setResponseTexts(newResponseTexts);
  };

  const handleFeedbackChange = async (
    index,
    feedback,
    responseText,
    questionID
  ) => {
    // modified
    try {
      const updatedQuestion = await API.graphql(
        graphqlOperation(updateQuestion, {
          input: {
            id: questionID,
            responseText: responseText,
            feedback: feedback
          }
        })
      );
      const newFeedbacks = [...feedbacks];
      newFeedbacks[index] = updatedQuestion.data.updateQuestion.feedback;
      setFeedbacks(newFeedbacks);
      const newResponseTexts = [...responseTexts];
      newResponseTexts[index] =
        updatedQuestion.data.updateQuestion.responseText;
    } catch (error) {
      console.log(error);
    }
  };
  const removeQuestion = questionID => {
    setQuestions(questions.filter(question => question.id !== questionID));
  };

  useEffect(() => {
    const fecthUserData = async () => {
      console.log(user);
      try {
        // Get the user associated with the user id
        const { data, errors } = await API.graphql(
          graphqlOperation(getUser, {
            id: user.username
          })
        );
        if (errors) {
          console.log("Errors:", errors);
        }

        // Check if user exists in the database, if not, create a new user
        if (!data.getUser) {
          console.log("made a user");
          await API.graphql(
            graphqlOperation(createUser, {
              input: {
                id: user.username,
                username: user.username,
                name: user.attributes.name,
                dailyQueries: 0,
                subscriptionType: "FREE"
              }
            })
          );
          setSubscriptionType("FREE");
          setDailyQueries(0);
        } else {
          console.log(data);
          setEmail(user.attributes.email);
          setName(user.attributes.name);
          setSubscriptionType(data.getUser.subscriptionType);
          setSubscriptionID(data.getUser.stripeSubscriptionID);
          setDailyQueries(data.getUser.dailyQueries);
          const currentDate = new Date();
          const today = currentDate.toISOString().split("T")[0];
          let totalCount = 0;
          data.getUser.dailyAnsweredQuestions.items.map(
            dailyAnsweredQuestion => {
              totalCount += dailyAnsweredQuestion.answeredQuestionsCount;
            }
          );
          const questionsTodayCount = data.getUser.dailyAnsweredQuestions.items.filter(
            dailyAnsweredQuestion => dailyAnsweredQuestion.date === today
          );
          const questionsToday = questionsTodayCount[0]
            ? questionsTodayCount[0].answeredQuestionsCount
            : 0;
          setTotalQuestions(totalCount);
          setQuestionsToday(questionsToday);
        }

        const qs = data.getUser.questions.items;
        console.log(qs);
        setQuestions(qs);
        qs.map((question, index) => {
          console.log("questions mapped");
          responseTexts[index] = question.responseText;
          feedbacks[index] = question.feedback;
          conversationText[index] = question.conversation;
        });
        setName(user.attributes.name);
      } catch (error) {
        console.log("error here" + error);
      }
    };
    user && fecthUserData();
    console.log("In use effect");
  }, []);

  //need to set the initial state once when component updated do not run again
  const questionCards = questions.map((question, index) => {
    return (
      <SavedQuestion
        key={index}
        question={question.questionText}
        questionID={question.id}
        removeQuestion={() => removeQuestion(question.id)}
        index={index}
        conversation={conversationText[index]}
        blobURL={blobURLs[index]}
        audioKey={audioKeys[index]}
        responseText={responseTexts[index]}
        feedback={feedbacks[index]}
        onBlobURLChange={blobURL => handleBlobURLChange(index, blobURL)}
        onAudioKeyChange={audioKey => handleAudioKeyChange(index, audioKey)}
        onResponseTextChange={responseText =>
          handleResponseTextChange(index, responseText)
        }
        onConversationTextChange={handleConversationTextChange}
        onFeedbackChange={feedback =>
          handleFeedbackChange(
            index,
            feedback,
            responseTexts[index],
            question.id
          )
        }
        saved={saved[index]}
        onChangeSave={handleChangeSave}
      />
    );
  });
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <Grid container>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center"
          }}
          item
          xs={12}
          xs={12}
          md={4}
        >
          {
            <ProfileCard
              name={name}
              subscriptionType={subscriptionType}
              email={email}
              subscriptionID={subscriptionID}
            />
          }
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center"
            }}
          >
            <Grid item xs={12} xs={10} md={10}>
              <ProgressStatistics
                totalQuestions={totalQuestions}
                questionsToday={questionsToday}
              />
            </Grid>
            <Grid item xs={12} xs={12} md={10}>
              <HorizontalScrollCalendar
                month={currentMonth}
                year={currentYear}
                userId={userId}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          justifyContent: "center",
          alignContent: "center"
        }}
        spacing={2}
        marginTop={2}
      >
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center"
          }}
          xs={12}
        >
          {loading ? (
            <CircularProgress />
          ) : questions.length > 0 ? (
            <Box
              sx={{
                borderRadius: "10px",
                maxWidth: "1100px",
                width: "100%",
                boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
                margin: 2,
                background:
                  "linear-gradient(135deg, #7B9FE4 30%, #A762F6 60%, #F57DC6 90%)"
              }}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  textAlign: "center",
                  fontFamily:
                    "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
                  fontWeight: 500,
                  marginBottom: 2,
                  paddingTop: 2
                }}
                variant="h6"
              >
                Practice your Saved Questions
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    overflowX: "auto",
                    flexDirection: "column",
                    justifyContent: "center"
                  }}
                >
                  {questionCards}
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                borderRadius: "10px",
                maxWidth: "650px",
                width: "100%",
                boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
                margin: 2,
                padding: 2,
                background:
                  "linear-gradient(135deg, #7B9FE4 30%, #A762F6 60%, #F57DC6 90%)"
              }}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  textAlign: "center",
                  fontFamily:
                    "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
                  fontWeight: 500
                }}
                variant="h6"
              >
                Your saved questions will appear here
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
