import React, { useState, useEffect } from "react";
import UserContext from "./UserContext";

function UserContextProvider({ children }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [stripeSubscriptionID, setStripeSubscriptionId] = useState("");
  const [stripeUserId, setStripeUserId] = useState("");
  const [subscriptionType, setSubscriptionType] = useState(
    localStorage.getItem("subscriptionType") || ""
  );
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("subscriptionType", subscriptionType);
  }, [user, subscriptionType]);
  return (
    <UserContext.Provider
      value={{
        name,
        setName,
        email,
        setEmail,
        setUser,
        user,
        setSubscriptionType,
        subscriptionType
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;
