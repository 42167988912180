import React from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import AudioRecorder from "./AudioRecorder";
import { Amplify, Auth, API, graphqlOperation } from "aws-amplify";
import { getUser } from "../graphql/queries";
import { createQuestion, createUser, updateUser } from "../graphql/mutations";
import ReplayIcon from "@mui/icons-material/Replay";
const QuestionCard = ({
  question,
  index,
  conversation,
  blobURL,
  audioKey,
  responseText,
  feedback,
  onBlobURLChange,
  onAudioKeyChange,
  onResponseTextChange,
  onConversationTextChange,
  onFeedbackChange,
  next,
  prev
}) => {
  const cardStyle = {
    maxWidth: "600px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
    zIndex: "2"
  };

  const buttonContainerStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
    zIndex: "2"
  };
  const headerStyle = {
    display: "flex",
    textAlign: "center",
    fontFamily: "'Inter', sans-serif",
    fontWeight: 450,
    fontSize: "24px",
    fontStyle: "normal",
    letterSpacing: "0.25px",
    lineHeight: "30px",
    color: "#000000",
    zIndex: "2",
    marginBottom: 2
  };
  const buttonStyle = {
    borderRadius: "50%",
    width: "3rem",
    height: "3rem",
    zIndex: "2"
  };
  const buttonStyle2 = {
    fontSize: "1.1rem",
    fontWeight: 500,
    fontFamily: "'Inter', sans-serif",
    padding: "0.7rem",
    lineHeight: "22px",
    paddingLeft: "1rem",
    textTransform: "none",
    paddingRight: "1rem",
    letterSpacing: "1px",
    color: "#FFFFFF",
    borderColor: "#3854e8",
    backgroundColor: "#3854e8",
    marginBottom: 2,
    borderRadius: "20px",
    zIndex: "2",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: "#5067cf"
    }
  };

  const saveQuestion = async () => {
    const user = await Auth.currentAuthenticatedUser();
    if (user.username === undefined) {
      //TODO: make sure to display a dialog to the user
      console.log("Create an account to save questions");
      return;
    }
    console.log("current convo" + JSON.stringify(conversation));
    const newQuestion = {
      questionText: question,
      feedback: feedback,
      responseText: responseText,
      qID: user.username,
      conversation: conversation
    };
    API.graphql(graphqlOperation(createQuestion, { input: newQuestion }))
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.error("Error creating question:", error);
      });
  };
  const handleRetry = () => {
    onBlobURLChange("");
    onAudioKeyChange("");
    onResponseTextChange("");
    onFeedbackChange("");
  };
  const handleBlobURLChange = blobURL => {
    onBlobURLChange(blobURL);
  };

  const handleAudioKeyChange = audioKey => {
    onAudioKeyChange(audioKey);
  };

  const handleResponseTextChange = responseText => {
    onResponseTextChange(responseText);
  };

  const handleFeedbackChange = feedback => {
    onFeedbackChange(feedback);
  };
  const handleConversationChange = conversation => {
    console.log("in the question card handle convo change");
    onConversationTextChange(conversation);
  };
  return (
    <Card sx={cardStyle}>
      <CardContent>
        <Typography sx={headerStyle} variant="h5">
          {question}
        </Typography>
      </CardContent>
      <CardContent>
        <AudioRecorder
          question={question}
          conversation={conversation}
          onConversationTextChange={handleConversationChange}
          blobURL={blobURL}
          audioKey={audioKey}
          responseText={responseText}
          feedback={feedback}
          onBlobURLChange={handleBlobURLChange}
          onAudioKeyChange={handleAudioKeyChange}
          onResponseTextChange={handleResponseTextChange}
          onFeedbackChange={handleFeedbackChange}
        />
      </CardContent>

      <CardContent sx={buttonContainerStyle}>
        {index > 0 && (
          <Button sx={buttonStyle} onClick={prev}>
            <NavigateBeforeIcon color="primary" />
          </Button>
        )}
        {/* TODO: cannot always be 9 should be the length of the questions array */}
        {index < 9 && (
          <Button sx={buttonStyle} onClick={next}>
            <NavigateNextIcon color="primary" />
          </Button>
        )}
        {blobURL || audioKey || responseText || feedback ? (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Button sx={buttonStyle} onClick={handleRetry}>
              <ReplayIcon />
            </Button>
          </div>
        ) : null}
      </CardContent>
      <Button
        sx={buttonStyle2}
        onClick={saveQuestion}
        variant="contained"
        color="primary"
      >
        Save Question
      </Button>
    </Card>
  );
};

export default QuestionCard;
