import "./App.css";
import React, { useEffect, useState, useContext } from "react";
import { Amplify, Auth, API, graphqlOperation } from "aws-amplify";
import config from "./aws-exports";
import Question from "./Component/Question";
import QuestionSolo from "./Component/QuestionSolo";
import QuestionTailored from "./Component/QuestionTailored";
import QuestionList from "./Component/QuestionList";
import MenuBar from "./Component/MenuBar";
import HomePage from "./Component/Homepage";
import UserContextProvider from "./Component/UserContextProvider";
import UpgradeMembership from "./Component/UpgradeMembership";
import ResumeQuestions from "./Component/ResumeQuestions";

import LogIn from "./Component/LogIn";
import Profile from "./Component/Profile";
import Footer from "./Component/Footer";
import ProtectedRoute from "./Component/ProtectedRoute";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UserContext from "./Component/UserContext";
import SocialSigninHandler from "./Component/SocialSinginHandler";
Amplify.configure(config);

function App() {
  const { setUser } = useContext(UserContext);
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        console.log(currentUser);
        setUser(currentUser);
      } catch (error) {
        console.log(error);
        setUser(null);
      }
    };
    checkAuth();
  }, []);
  return (
    <Router>
      <UserContextProvider>
        <div className="App">
          <MenuBar />
          <AppRoutes />
          <Footer />
        </div>
      </UserContextProvider>
    </Router>
  );
}
function AppRoutes() {
  //set all possible routes that the user may navigate to
  return (
    <Routes>
      <Route path="*" element={<HomePage />}></Route>
      <Route path="/jobTailored" element={<QuestionTailored />}></Route>
      <Route path="/import" element={<QuestionList />}></Route>
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      ></Route>
      <Route path="/job" element={<QuestionSolo />}></Route>
      <Route path="/resumeQuestions" element={<ResumeQuestions />}></Route>
      <Route path="/login" element={<LogIn />}></Route>
      <Route path="/upgrade" element={<UpgradeMembership />}></Route>
      <Route path="/social" element={<SocialSigninHandler />}></Route>
    </Routes>
  );
}
export default App;
