import React, { useState, useEffect } from "react";
import { Amplify, Auth, API, graphqlOperation } from "aws-amplify";
import { getUser } from "../graphql/queries";
import SavedQuestion from "./SavedQuestion";
import { CircularProgress } from "@mui/material";
import {
  TextField,
  Button,
  List,
  ListItem,
  Box,
  ListItemSecondaryAction,
  IconButton,
  Typography
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const QuestionList = () => {
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState("");
  const [conversationText, setConversationText] = useState([]);
  const [blobURLs, setBlobURLs] = useState([]);
  const [audioKeys, setAudioKeys] = useState([]);
  const [responseTexts, setResponseTexts] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [saved, setSaved] = useState([]);
  const handleBlobURLChange = (index, blobURL) => {
    const newBlobURLs = [...blobURLs];
    newBlobURLs[index] = blobURL;
    setBlobURLs(newBlobURLs);
  };

  const handleAudioKeyChange = (index, audioKey) => {
    const newAudioKeys = [...audioKeys];
    newAudioKeys[index] = audioKey;
    setAudioKeys(newAudioKeys);
  };
  const handleConversationTextChange = newItems => {
    setConversationText([...conversationText, ...newItems]);
    console.log([...conversationText, ...newItems]);
  };
  const handleResponseTextChange = (index, responseText) => {
    const newResponseTexts = [...responseTexts];
    newResponseTexts[index] = responseText;
    setResponseTexts(newResponseTexts);
  };

  const handleFeedbackChange = (index, feedback) => {
    const newFeedbacks = [...feedbacks];
    newFeedbacks[index] = feedback;
    setFeedbacks(newFeedbacks);
  };
  const handleChangeSave = (index, save) => {
    console.log("updated save " + index + " " + save);
    const newSaves = [...saved];
    newSaves[index] = save;
    setSaved(newSaves);
    console.log(newSaves);
  };
  const removeQuestion = questionID => {
    setQuestions(questions.filter(question => question.id !== questionID));
  };
  useEffect(() => {
    /* fetch users saved questions
    const fecthUserData = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user);
        //get the user associated with the user id, so when create question all need is user id
        const { data } = await API.graphql(
          graphqlOperation(getUser, {
            id: user.username
          })
        );
        console.log("user effect ");
        setQuestions(data.getUser.questions.items);
        setName(user.attributes.name);
      } catch (error) {
        console.log(error);
        setUser(null);
      }
    };
    fecthUserData();
    */
  }, []);

  //need to set the initial state once when component updated do not run again
  const questionCards = questions.map((question, index) => {
    return (
      <div>
        <SavedQuestion
          key={index}
          question={question}
          questionID={index} //TODO: generate a qid when add question to database
          removeQuestion={() => removeQuestion(index)}
          index={index}
          conversation={conversationText}
          blobURL={blobURLs[index]}
          audioKey={audioKeys[index]}
          responseText={responseTexts[index]}
          feedback={feedbacks[index]}
          onBlobURLChange={blobURL => handleBlobURLChange(index, blobURL)}
          onAudioKeyChange={audioKey => handleAudioKeyChange(index, audioKey)}
          onResponseTextChange={responseText =>
            handleResponseTextChange(index, responseText)
          }
          onConversationTextChange={handleConversationTextChange}
          onFeedbackChange={feedback => handleFeedbackChange(index, feedback)}
          saved={saved[index]}
          onChangeSave={handleChangeSave}
        />
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => handleQuestionDelete(index)}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  });
  const handleQuestionChange = event => {
    setNewQuestion(event.target.value);
  };

  const handleQuestionSubmit = () => {
    setQuestions([...questions, newQuestion]);
    setNewQuestion("");
  };

  const handleQuestionDelete = indexToDelete => {
    setQuestions(questions.filter((_, index) => index !== indexToDelete));
    const newBlobURLs = [...blobURLs]; // create a copy of the original array
    newBlobURLs.splice(indexToDelete, 1); // remove the element at the specified index
    setBlobURLs(newBlobURLs); // update the state with the new array
    const newAudioKeys = [...audioKeys]; // create a copy of the original array
    newAudioKeys.splice(indexToDelete, 1); // remove the element at the specified index
    setAudioKeys(newAudioKeys); // update the state with the new array
    const newResponseTexts = [...responseTexts];
    newResponseTexts.splice(indexToDelete, 1);
    setResponseTexts(newResponseTexts);
    const newFeedbacks = [...feedbacks];
    newFeedbacks.splice(indexToDelete, 1);
    setFeedbacks(newFeedbacks);
    const newConversation = [...conversationText];
    newConversation.splice(indexToDelete, 1);
    setConversationText(newConversation);
    const newSaved = [...saved];
    newSaved.splice(indexToDelete, 1);
    setSaved(newSaved);
  };
  const boxStyle = {
    display: "flex",
    padding: { xs: 2, sm: 4, md: 6, lg: 8 },
    alignItems: "center",
    flexDirection: "column",
    minHeight: "100%"
  };
  const headerStyle = {
    display: "flex",
    textAlign: "center",
    fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
    fontWeight: 550,
    fontSize: "30px",
    fontStyle: "normal",
    letterSpacing: "0.25px",
    lineHeight: "30px",
    color: "#000000",
    zIndex: "2",
    marginBottom: 2
  };
  const additionalText = {
    display: "flex",
    alignContent: "left",
    fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
    fontWeight: 450,
    fontSize: "18px",
    letterSpacing: "0.25px",
    fontStyle: "normal",
    lineHeight: "30px",
    zIndex: "2",
    color: "#000000",
    marginBottom: 4
  };
  const buttonStyle = {
    fontSize: "1.2rem",
    fontWeight: 600,
    fontFamily: "'Inter', sans-serif",
    padding: "0.7rem",
    lineHeight: "22px",
    paddingLeft: "1rem",
    textTransform: "none",
    paddingRight: "1rem",
    letterSpacing: "1px",
    color: "#000000",
    borderColor: "#3854e8",
    marginBottom: 2,
    textColor: "#00000",
    borderRadius: "20px",
    zIndex: "2",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)"
    }
  };
  const inputStyle = {
    fontFamily: "'Inter', sans-serif",
    marginBottom: 2,
    zIndex: "2",
    "& .MuiOutlinedInput-root": {
      fontSize: "1.2rem",
      fontWeight: 800,
      lineHeight: "22px",
      letterSpacing: "1px",
      borderRadius: "20px",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#3854e8"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#3854e8"
      }
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "1.2rem",
      fontWeight: 545,
      letterSpacing: "1px",

      "&.Mui-focused": {
        color: "#000000"
      }
    }
  };
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: 0,
          zIndex: "-1",
          left: 0,
          width: "100%",
          height: "325px",
          background: "linear-gradient(90deg, #ccdefb 0%, #808cde 100%)",
          opacity: 1,
          transform: "skewY(-9deg)",
          transformOrigin: "bottom left"
        }}
      />
      <Box component="form" sx={boxStyle} noValidate autoComplete="off">
        <Typography sx={headerStyle} variant="h5">
          Import your own questions and have your responses analyzed!
        </Typography>
        <Typography sx={additionalText} variant="body1">
          Enter questions you expect to be asked in an interview one by one and
          we'll test you on them.
        </Typography>
        <TextField
          sx={inputStyle}
          label="Type your question"
          value={newQuestion}
          onChange={handleQuestionChange}
          variant="outlined"
          fullWidth
        />

        <Button
          sx={buttonStyle}
          variant="contained"
          onClick={handleQuestionSubmit}
          disabled={!newQuestion}
        >
          Submit
        </Button>
        {questionCards}
      </Box>
    </div>
  );
};

export default QuestionList;
