import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setAuthenticated(true);
      } catch (error) {
        navigate("/login");
      }
    };
    checkAuth();
  }, [navigate]);

  return authenticated ? children : null;
};

export default ProtectedRoute;
