import React, { useEffect } from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";
import AudioRecorder from "./AudioRecorder";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { deleteQuestion, createQuestion } from "../graphql/mutations";
import ReplayIcon from "@mui/icons-material/Replay";
import { useLocation } from "react-router-dom";
const SavedQuestion = ({
  question,
  questionID,
  removeQuestion,
  conversation,
  blobURL,
  audioKey,
  responseText,
  feedback,
  onBlobURLChange,
  onAudioKeyChange,
  onResponseTextChange,
  onConversationTextChange,
  onFeedbackChange,
  saved,
  onChangeSave
}) => {
  const cardStyle = {
    maxWidth: "600px",
    minWidth: "450px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
    marginBottom: 4,
    height: "fit-content",
    margin: "10px"
  };

  const buttonContainerStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem"
  };

  const buttonStyle = {
    borderRadius: "50%",
    width: "3rem",
    height: "3rem"
  };
  const buttonStyle2 = {
    fontSize: "1.1rem",
    fontWeight: 500,
    fontFamily: "'Inter', sans-serif",
    padding: "0.7rem",
    lineHeight: "22px",
    paddingLeft: "1rem",
    textTransform: "none",
    paddingRight: "1rem",
    letterSpacing: "1px",
    color: "#FFFFFF",
    borderColor: "#3854e8",
    backgroundColor: "#3854e8",
    marginBottom: 2,
    borderRadius: "20px",
    zIndex: "2",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: "#5067cf"
    }
  };
  //TODO: Rework to access user from context
  const saveQuestion = async () => {
    const user = await Auth.currentAuthenticatedUser();
    if (user.username === undefined) {
      //TODO: make sure to display a dialog to the user
      console.log("Create an account to save questions");
      return;
    }
    console.log("current convo" + JSON.stringify(conversation));
    const newQuestion = {
      questionText: question,
      feedback: feedback,
      responseText: responseText,
      qID: user.username,
      conversation: conversation
    };
    handleChangeSave(0);
    API.graphql(graphqlOperation(createQuestion, { input: newQuestion }))
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.error("Error creating question:", error);
      });
  };

  const deleteQuestionById = async () => {
    try {
      const input = { id: questionID };
      const response = await API.graphql(
        graphqlOperation(deleteQuestion, { input })
      );
      handleChangeSave(1);
      console.log("Deleted question:", response);
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };
  const handleRetry = () => {
    onBlobURLChange("");
    onAudioKeyChange("");
    onResponseTextChange("");
    onFeedbackChange("");
  };
  const handleBlobURLChange = blobURL => {
    onBlobURLChange(blobURL);
  };

  const handleAudioKeyChange = audioKey => {
    onAudioKeyChange(audioKey);
  };

  const handleResponseTextChange = responseText => {
    onResponseTextChange(responseText);
  };

  const handleFeedbackChange = feedback => {
    onFeedbackChange(feedback);
  };
  const handleChangeSave = saved => {
    onChangeSave(questionID, saved);
  };
  const handleConversationChange = conversation => {
    onConversationTextChange(conversation);
  };
  return (
    <Card sx={cardStyle}>
      <CardContent>
        <Typography variant="h5">{question}</Typography>
      </CardContent>
      <CardContent>
        <AudioRecorder
          question={question}
          conversation={conversation}
          onConversationTextChange={handleConversationChange}
          blobURL={blobURL}
          audioKey={audioKey}
          responseText={responseText}
          feedback={feedback}
          onBlobURLChange={handleBlobURLChange}
          onAudioKeyChange={handleAudioKeyChange}
          onResponseTextChange={handleResponseTextChange}
          onFeedbackChange={handleFeedbackChange}
        />
      </CardContent>

      <CardContent sx={buttonContainerStyle}>
        {blobURL || audioKey || responseText || feedback ? (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Button sx={buttonStyle} onClick={handleRetry}>
              <ReplayIcon />
            </Button>
          </div>
        ) : null}
      </CardContent>
      {saved === 1 ? (
        <Button
          sx={buttonStyle2}
          onClick={saveQuestion}
          variant="outlined"
          color="primary"
        >
          Save
        </Button>
      ) : (
        <Button
          sx={buttonStyle2}
          onClick={() => deleteQuestionById(questionID)}
          variant="outlined"
          color="primary"
        >
          Unsave
        </Button>
      )}
    </Card>
  );
};

export default SavedQuestion;
