import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  CardHeader
} from "@mui/material";

export default function PaymentPlans() {
  const boxStyle = {
    flexDirection: "column",
    backgroundColor: "white",
    display: "flex",
    border: "4px solid #2232ac",
    borderRadius: "20px",
    zIndex: "2",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)"
  };
  const buttonStyle = {
    fontSize: "1.2rem",
    fontWeight: 700,
    fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
    padding: "0.7rem",
    lineHeight: "22px",
    paddingLeft: "1rem",
    textTransform: "none",
    paddingRight: "1rem",
    letterSpacing: "1px",
    color: "#000000",
    borderColor: "#2232ac",
    marginBottom: 2,
    textColor: "#00000",
    borderRadius: "20px",
    zIndex: "2",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)"
    }
  };
  const headerStyle = {
    fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
    fontWeight: 500,
    fontSize: "30px",
    fontStyle: "normal",
    letterSpacing: "0.25px",
    lineHeight: "30px",
    color: "#FFFFFF",
    zIndex: "2"
  };
  const additionalText = {
    display: "flex",
    alignContent: "left",
    fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
    fontWeight: 450,
    fontSize: "18px",
    letterSpacing: "0.25px",
    fontStyle: "normal",
    lineHeight: "30px",
    zIndex: "2",
    color: "#000000",
    marginBottom: 1
  };
  return (
    <Box
      sx={{
        backgroundColor: "#2232ac",
        display: "flex",
        padding: { xs: 2, sm: 4, md: 6, lg: 8 },
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100%",
        position: "relative"
      }}
    >
      <Typography
        variant="h2"
        sx={{
          display: "flex",
          alignContent: "left",
          fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
          fontWeight: 700,
          fontSize: "22px",
          fontStyle: "normal",
          zIndex: "2",
          lineHeight: "30px",
          color: "#000000",
          marginBottom: 4
        }}
        id="pricing"
      >
        Membership Options
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          maxWidth: 800,
          zIndex: "2",
          flexWrap: "wrap" // Add this to wrap the cards on smaller screens.
        }}
      >
        <Grid item xs={12} sm={6} sx={{ marginBottom: 2 }}>
          <Card xs={12} sx={{ ...boxStyle, maxWidth: 345 }}>
            <CardHeader
              title="Free"
              sx={{
                backgroundColor: "#2232ac" // Set the desired background color here
              }}
              titleTypographyProps={headerStyle}
            />
            <CardContent>
              <Typography
                component="span"
                sx={{
                  fontFamily:
                    "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
                  fontSize: "24px",
                  fontWeight: 600,
                  color: "#000000"
                }}
              >
                $0
              </Typography>
              <Typography
                component="span"
                sx={{
                  fontFamily:
                    "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#A9A9A9",
                  marginLeft: "0.25rem"
                }}
              >
                /mo
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  ...additionalText,
                  marginTop: 1
                }}
              >
                Daily limit to receive response feedback
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  ...additionalText
                }}
              >
                Personalized feedback on interview performance
              </Typography>

              <Button
                variant="outlined"
                color="primary"
                component={Link}
                to="/login"
                sx={{
                  marginTop: 2,
                  ...buttonStyle
                }}
              >
                Sign Up For Free
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ marginBottom: 2 }}>
          <Card xs={12} sx={{ maxWidth: 345, ...boxStyle }}>
            <CardHeader
              title="Premium"
              sx={{
                backgroundColor: "#2232ac" // Set the desired background color here
              }}
              titleTypographyProps={headerStyle}
            />
            <CardContent>
              <Typography
                component="span"
                sx={{
                  fontSize: "24px",
                  fontFamily: "'nunito', sans-serif",
                  fontWeight: 600,
                  color: "#000000"
                }}
              >
                $4.99
              </Typography>
              <Typography
                component="span"
                sx={{
                  fontSize: "16px",
                  fontFamily: "'nunito', sans-serif",
                  fontWeight: 500,
                  color: "#A9A9A9",
                  marginLeft: "0.25rem"
                }}
              >
                /mo
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  ...additionalText,
                  marginTop: 1
                }}
              >
                Full access to interview questions and scenarios
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  ...additionalText
                }}
              >
                Unlimited feedback on interview performance
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  ...additionalText
                }}
              >
                Access to exclusive interview resources and tips
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                component={Link}
                to="/login"
                sx={{
                  marginTop: 2,
                  ...buttonStyle
                }}
              >
                Get Started
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Box>
      <div style={{ overflow: "hidden" }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            zIndex: "1",
            left: 0,
            width: "100%", // set a wider width
            height: "400px", // set a shorter height
            background: "linear-gradient(90deg, #ccdefb 0%, #808cde 100%)",
            transform: "skewY(-9deg)",
            transformOrigin: "bottom left"
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            top: 0,
            zIndex: "1",
            left: 0,
            width: "100%", // set a wider width
            height: "100%",
            background: "linear-gradient(90deg, #ccdefb 0%, #808cde 100%)"
          }}
        ></div>
      </div>
    </Box>
  );
}
