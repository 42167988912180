import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Gradient from "../static/desktop1.jpg";
const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3
    }
  }
};

const textVariants = {
  hidden: custom => ({
    x: custom.direction * -100,
    opacity: 0
  }),
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100
    }
  }
};

const HeroSection = () => {
  const handleClick = () => {};

  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: 0,
          zIndex: "-1",
          left: 0,
          width: "100%",
          height: "325px",
          backgroundImage: `url(${Gradient})`, // Change this line
          backgroundSize: "cover", // Add this line
          backgroundPosition: "center", // Add this line
          opacity: 1,
          transform: "skewY(-9deg)",
          transformOrigin: "bottom left"
        }}
      />
      <Box
        sx={{
          color: "#ffffff",
          padding: { xs: 2, sm: 4, md: 6, lg: 8 }
        }}
      >
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.div custom={{ direction: 1 }} variants={textVariants}>
            <Typography
              variant="h1"
              sx={{
                display: "flex",
                fontWeight: 700,
                fontSize: { xs: "50px", sm: "75px" },
                lineHeight: "111px",
                color: "#0e1111",
                letterSpacing: "0.2px",
                fontFamily: "'Inter', sans-serif",
                textShadow: "1px 1px 1px rgba(0, 0, 0, 0.1)",
                textAlign: "left" // Add this line
              }}
            >
              Ace Your Upcoming
              <br />
              Job Interview
            </Typography>
          </motion.div>
          <motion.div custom={{ direction: -1 }} variants={textVariants}>
            <Typography
              variant="h2"
              sx={{
                display: "flex",
                alignContent: "left",
                fontFamily:
                  "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
                fontWeight: 600,
                fontSize: "24px",
                fontStyle: "normal",
                lineHeight: "22px",
                letterSpacing: "0.25px",
                textShadow: "1px 1px 1px rgba(0, 0, 0, 0.05)",
                color: "#000000",
                marginTop: 2,
                marginBottom: 4,
                textAlign: "left" // Add this line
              }}
            >
              Personalized practice for your dream job
            </Typography>
          </motion.div>
          <motion.div
            style={{ display: "flex", alignContent: "left" }}
            custom={{ direction: 1 }}
            variants={textVariants}
          >
            <Button
              component={Link}
              to="/login"
              variant="outlined"
              className="hero-section button"
              sx={{
                fontSize: "1.2rem",
                fontFamily:
                  "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
                fontWeight: 600,
                padding: "0.7rem",
                lineHeight: "22px",
                paddingLeft: "1rem",
                textTransform: "none",
                paddingRight: "1rem",
                letterSpacing: "1px",
                backgroundColor: "transparent",
                color: "#000000",
                borderColor: "#3854e8",
                textColor: "#00000",
                borderRadius: "20px",
                textShadow: "1px 1px 1px rgba(0, 0, 0, 0.05)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)"
                }
              }}
            >
              Get Started
            </Button>
          </motion.div>
          <motion.div
            style={{
              display: "flex",
              alignContent: "left",
              flexDirection: "column"
            }}
            custom={{ direction: 1 }}
            variants={textVariants}
          >
            <Typography
              variant="h2"
              sx={{
                display: "flex",
                alignContent: "left",
                fontFamily:
                  "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
                fontWeight: 500,
                fontSize: "24px",
                letterSpacing: "0.25px",
                fontStyle: "normal",
                lineHeight: "30px",
                color: "#000000",
                marginTop: 7,
                marginBottom: 3,
                textShadow: "1px 1px 1px rgba(0, 0, 0, 0.05)"
              }}
            >
              Why Interview Lab Co.?
            </Typography>
            <Typography
              variant="h2"
              sx={{
                display: "flex",
                alignContent: "left",
                letterSpacing: "0.25px",
                fontFamily:
                  "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
                fontWeight: 450,
                fontSize: "16px",
                fontStyle: "normal",
                lineHeight: "30px",
                color: "#000000",
                textShadow: "1px 1px 1px rgba(0, 0, 0, 0.05)",
                marginBottom: 4
              }}
            >
              Because it's better than LinkedIn.
            </Typography>
          </motion.div>
        </motion.div>
      </Box>
    </div>
  );
};

export default HeroSection;
