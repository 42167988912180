import React, { useEffect, useRef, useContext, useState } from "react";
import "./HorizontalScrollCalendar.css";
import { Typography, Box } from "@mui/material";
import { API, graphqlOperation } from "aws-amplify";
import { listDailyAnsweredQuestions } from "../graphql/queries";
const HorizontalScrollCalendar = ({ month, year, userId }) => {
  const daysInMonth = (month, year) => new Date(year, month, 0).getDate();
  const calendarRef = useRef(null);

  const totalDays = daysInMonth(month, year);
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const fetchDailyAnsweredQuestions = async userId => {
    try {
      const response = await API.graphql(
        graphqlOperation(listDailyAnsweredQuestions, {
          filter: { userID: { eq: userId } }
        })
      );

      if (response.data.listDailyAnsweredQuestions.items.length > 0) {
        // Process the data and update the state
        const completedActionsData = response.data.listDailyAnsweredQuestions.items.reduce(
          (acc, item) => {
            acc[new Date(item.date).getDate()] = item.answeredQuestionsCount;
            return acc;
          },
          []
        );
        setCompletedActions(completedActionsData);
      }
    } catch (error) {
      console.error("Error fetching daily answered questions:", error);
    }
  };
  const [completedActions, setCompletedActions] = useState([]);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const formattedDate = `${
    monthNames[currentDate.getMonth()]
  } ${currentDate.getDate()}, ${currentDate.getFullYear()}`;

  useEffect(() => {
    if (
      calendarRef.current &&
      currentDate.getMonth() === month - 1 &&
      currentDate.getFullYear() === year
    ) {
      const dayWidth = 60; // The width of each .day-wrapper element
      const calendarWidth = calendarRef.current.clientWidth;
      const scrollPosition =
        dayWidth * (currentDay - 1) - calendarWidth / 2 + dayWidth / 2;
      calendarRef.current.scrollLeft = scrollPosition;
    }
  }, [calendarRef, month, year, currentDay]);

  useEffect(() => {
    fetchDailyAnsweredQuestions(userId);
  }, [userId]);

  return (
    <Box
      sx={{
        borderRadius: "10px",
        boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
        padding: "2rem",
        margin: 2
      }}
    >
      <Typography
        sx={{
          display: "flex",
          color: "#A762F6",
          justifyContent: "center",
          fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
          fontWeight: 500,
          marginBottom: 2
        }}
        variant="h6"
      >
        Your Progress Calendar
      </Typography>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "left",
          alignContent: "left",
          fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
          color: "#425466",
          marginBottom: 2
        }}
      >
        Keep up the good work! Filling your daily rings can help you stay on
        track towards your goals. Answer more questions to fill the rings!
      </Typography>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
          color: "#425466",
          marginBottom: 2
        }}
        variant="subtitle2"
      >
        {formattedDate}
      </Typography>
      <div
        style={{ marginBottom: 3 }}
        className="horizontal-scroll-calendar"
        ref={calendarRef}
      >
        <div className="days-container">
          {[...Array(totalDays)].map((_, index) => {
            const completed = completedActions[index] || 0;
            return (
              <div key={index} className="day-wrapper">
                <div className={`circle-outline circle-${completed}`}>
                  <span className="day-number">{index + 1}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Box>
  );
};

export default HorizontalScrollCalendar;
