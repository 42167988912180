import React from "react";
import { Container, Box, Typography, Link } from "@mui/material";
import OpenAILogo from "../static/openai-logomark.svg"; // Adjust the path to match where you saved the logo

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#5067cf",
        zIndex: "2",
        color: theme => theme.palette.primary.contrastText,
        padding: theme => theme.spacing(2)
      }}
    >
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            flexDirection: { xs: "column", sm: "row" },
            textAlign: { xs: "center", sm: "left" }
          }}
        >
          <Typography variant="body1" sx={{ mb: { xs: 1, sm: 0 } }}>
            &copy; {new Date().getFullYear()} Interview Lab Co.
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              flexDirection: { xs: "column", sm: "row" },
              mb: { xs: 1, sm: 0 }
            }}
          >
            <Typography
              sx={{ marginRight: { xs: 0, sm: "0.5rem" } }}
              variant="body2"
            >
              Powered by GPT-4 and Whisper
            </Typography>
            <Box mt={1}>
              <img src={OpenAILogo} alt="OpenAI Logo" height="30" />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: { xs: "center", sm: "flex-start" }
            }}
          >
            <Link
              href="#"
              color="inherit"
              sx={{ marginRight: { xs: 0, sm: "1rem" }, mb: { xs: 1, sm: 0 } }}
            >
              Privacy Policy
            </Link>
            <Link href="#" color="inherit">
              Terms & Conditions
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
