import React, { useState, useEffect, useContext } from "react";
import { CircularProgress, Grid, Button, Box, Typography } from "@mui/material";
import { AccountCircle, Email, Star } from "@mui/icons-material";
import { Amplify, Auth, API, graphqlOperation, Hub } from "aws-amplify";
import { createUser, updateUser } from "../graphql/mutations";
import PersonOutline from "@mui/icons-material/PersonOutline";
import UserContext from "./UserContext";
import { useNavigate } from "react-router-dom";
export default function ProfileCard({
  name,
  subscriptionType,
  email,
  subscriptionID
}) {
  const navigate = useNavigate();
  const { onSuccess, user, setUser, setSubscriptionType } = useContext(
    UserContext
  );
  const handleCancelSubscription = async () => {
    try {
      const response = await API.post(
        "chatEndpoints",
        "/chat/cancel_subscription",
        {
          body: {
            subscriptionId: subscriptionID
          },
          headers: { "Content-Type": "application/json" }
        }
      );
      console.log(response);
      try {
        await API.graphql(
          graphqlOperation(updateUser, {
            input: {
              id: user.username,
              subscriptionType: "FREE"
            }
          })
        );
        setSubscriptionType("FREE");
        console.log("User updated with subscription and customer IDs");
      } catch (error) {
        console.log("error updating db user table" + error);
      }
    } catch (error) {
      console.log("error reaching endpoint" + error);
    }
  };
  const handleUpgradeClick = event => {
    event.preventDefault();
    navigate("/upgrade", {
      state: { onSuccess, name, email }
    });
  };
  const buttonStyle = {
    fontSize: "1.2rem",
    fontWeight: 450,
    fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
    padding: "0.7rem",
    lineHeight: "22px",
    paddingLeft: "1rem",
    textTransform: "none",
    paddingRight: "1rem",
    letterSpacing: "1px",

    borderColor: "#3854e8",
    marginBottom: 2,
    textColor: "#00000",
    borderRadius: "20px",
    zIndex: "2",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)"
    }
  };
  return (
    <Box
      padding={4}
      sx={{
        background:
          "linear-gradient(45deg, #7B9FE4 30%, #A762F6 60%, #F57DC6 90%)",
        color: "primary.contrastText",
        display: "flex",
        flexDirection: "column",
        width: "fit-content",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 2,
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        height: { xs: "auto", sm: "auto", md: "47.5vh" },
        margin: { xs: 4 }
      }}
    >
      {name && (
        <Typography
          variant="h5"
          marginBottom={2}
          sx={{
            display: "flex",
            alignItems: "center",
            fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
            fontWeight: 500
          }}
        >
          <PersonOutline sx={{ marginRight: 1 }} />
          Hi, {name}
        </Typography>
      )}
      <Typography
        variant="body1"
        marginBottom={1}
        sx={{
          display: "flex",
          alignItems: "center",
          fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
          fontWeight: 450
        }}
      >
        <Star sx={{ marginRight: 1 }} />
        Membership:
        {subscriptionType &&
          " " + subscriptionType[0] + subscriptionType.slice(1).toLowerCase()}
      </Typography>
      <Typography
        variant="body1"
        marginBottom={1}
        sx={{
          display: "flex",
          align: "center",
          fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
          fontWeight: 450
        }}
      >
        <Email sx={{ marginRight: 1 }} />
        Email: {email}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "row", sm: "row", md: "column", lg: "row" }
        }}
      >
        {subscriptionType === "FREE" && (
          <div>
            <Button
              onClick={handleUpgradeClick}
              sx={{ ...buttonStyle, backgroundColor: "#2232ac", margin: 1 }}
              variant="contained"
            >
              Upgrade
            </Button>
          </div>
        )}
        {subscriptionType === "PREMIUM" && subscriptionID && (
          <Button
            variant="outlined"
            color="inherit"
            sx={{ ...buttonStyle, margin: 1 }}
            onClick={() => {
              handleCancelSubscription();
            }}
          >
            Cancel subscription
          </Button>
        )}
        <Button
          variant="outlined"
          color="inherit"
          sx={{ ...buttonStyle, margin: 1 }}
          onClick={() => {
            try {
              Auth.signOut().then(() => {
                setUser(null);
                //TODO set the subscription type too i think
                console.log("Signed Out");
                navigate("/");
              });
            } catch (error) {
              console.log(error);
            }
          }}
        >
          Sign out
        </Button>
      </Box>
    </Box>
  );
}
