import React, { createContext } from "react";

const UserContext = createContext({
  stripeSubscriptionID: "",
  stripeUserId: "",
  user: null,
  subscriptionType: "",
  setUser: () => {}
});

export default UserContext;
