import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import "./SkewedBackground.css";
import { scroller } from "react-scroll";
import Step1 from "../static/step1-1.gif";
import Step2 from "../static/step2-1.gif";
import Step3 from "../static/step3-1.gif";
import Step4 from "../static/step4-1.gif";
const steps = [Step1, Step2, Step3, Step4];
const boxStyle = {
  width: "100%",
  height: "18rem",
  zIndex: "2",
  backgroundColor: "white",
  border: "2px solid #3854E8",
  borderRadius: "20px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
  overflow: "hidden"
};
const textBoxStyle = {
  textAlign: "center",
  backgroundColor: "#FFFFFF",
  borderRadius: "10px",
  boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
  padding: "2rem"
};
const titles = [
  "Enter Your Job Position",
  "Record Your Response",
  "Edit Your Response Transcript",
  "Receive Feedback and Continue Practicing"
];
const subtext = [
  "In the first step, simply enter the job position you are interviewing for. This will allow our application to customize the interview questions and scenarios to match the specific role you are preparing for. Make sure to enter the exact job title and any relevant keywords to ensure the best practice experience.",
  "In the second step, our application will generate a question based on the job position you entered in the first step. You will then be prompted to record your response. This feature allows you to practice your interview skills in a simulated environment and receive feedback on your responses. Our application uses advanced speech recognition technology to evaluate your responses and provide personalized feedback",
  "In the third step, you can review and edit the text transcription of your recorded response. Our application uses advanced speech recognition technology to automatically transcribe your response, but sometimes errors may occur. Editing the transcript allows you to correct any errors and ensure the accuracy of your recorded response. You can also use this feature to refine your answers and make any necessary adjustments to improve your communication skills. Once you are satisfied with your edited transcript, you can move on to the next step",
  "In the final step, you will receive feedback on your recorded response and have several options to continue practicing. Our application evaluates your response based on various factors, and provides personalized feedback to help you improve. You can choose to save the question to practice later, retry the question to further refine your answer, or practice on another generated question to enhance your interview skills. Our application provides a seamless and interactive experience to help you prepare for your interview with confidence. Start practicing now and ace your next interview!"
];
const HowItWorks = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      scroller.scrollTo(location.state.scrollTo, {
        duration: 500,
        smooth: true,
        offset: -70
      });
    }
  }, [location]);
  const rows = 4;
  return (
    <Box
      sx={{
        backgroundColor: "#EDEDED",
        display: "flex",
        padding: { xs: 2, sm: 4, md: 6, lg: 8 },
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100%"
      }}
    >
      <Typography
        variant="h2"
        id="how-it-works"
        color="textPrimary"
        sx={{
          fontFamily: "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
          fontWeight: 500,
          fontSize: "24px",
          letterSpacing: "0.25px",
          fontStyle: "normal",
          lineHeight: "30px",
          marginTop: 2,
          textShadow: "1px 1px 1px rgba(0, 0, 0, 0.05)"
        }}
      >
        How It Works
      </Typography>
      <Grid
        container
        sx={{
          maxWidth: "1100px"
        }}
      >
        {Array.from({ length: rows }).map((_, index) => (
          <Grid
            sx={{
              width: "100%",
              marginTop: 6,
              zIndex: "2",
              padding: 3,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 2,
              flexDirection: index % 2 === 0 ? "row" : "row-reverse"
            }}
            className={clsx(
              index % 2 === 0
                ? "skewed-background"
                : "skewed-background-opposite"
            )}
            key={index}
            container
          >
            <Grid
              item
              xs={12}
              sm={5.25}
              sx={{
                ...boxStyle,
                zIndex: "2",
                width: "314px",
                marginBottom: { xs: 3, md: 0 }
              }}
            >
              <embed
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  objectFit: "scale-down"
                }}
                src={steps[index]}
              ></embed>
            </Grid>
            <Grid item xs={0} sm={1.5} />
            <Grid
              item
              sx={{
                ...textBoxStyle,
                zIndex: "2"
              }}
              xs={12}
              sm={5.25}
            >
              <Typography
                variant="h5"
                color="textPrimary"
                sx={{
                  fontFamily:
                    "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
                  fontWeight: 450,
                  fontSize: "18px",
                  fontStyle: "normal",
                  lineHeight: "26px",
                  marginBottom: 1
                }}
              >
                {titles[index]}
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                sx={{
                  fontFamily:
                    "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
                  fontWeight: 350,
                  fontSize: "16px",
                  fontStyle: "normal",
                  lineHeight: "28px"
                }}
              >
                {subtext[index]}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HowItWorks;
