import React, { useEffect } from "react";
import Question from "./Question";
import Hero from "./Hero";
import HowItWorks from "./HowItWorks";
import PaymentPlans from "./PaymentPlans";

export default function Homepage(props) {
  // Inside the homepage component
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        minHeight: "100vh" // This ensures the parent div takes up the full height of the viewport
      }}
    >
      <Hero />
      <HowItWorks id="how-it-works" />
      <Question />
      <PaymentPlans />
    </div>
  );
}
