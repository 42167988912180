import React, { useContext, useEffect } from "react";
import UserContext from "./UserContext";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
export default function SocialSinginHandler() {
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        console.log(currentUser);
        setUser(currentUser);
        navigate("/profile");
      } catch (error) {
        console.log(error);
        setUser(null);
        navigate("/login");
      }
    };
    checkAuth();
  });
  return null;
}
