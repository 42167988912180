import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import PersonOutline from "@mui/icons-material/PersonOutline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const pages = [
  { name: "Job Position Tailored", link: "/job" },
  { name: "Job Posting Tailored", link: "/jobTailored" },
  { name: "Import Questions", link: "/import" },
  { name: "Resume Tailored Questions", link: "/resumeQuestions" }
];

function MenuBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const [anchorElNavPractice, setAnchorElNavPractice] = React.useState(null);

  const handleOpenPracticeMenu = event => {
    setAnchorElNavPractice(event.currentTarget);
  };

  const handleClosePracticeMenu = () => {
    setAnchorElNavPractice(null);
  };

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const navigate = useNavigate();
  const closeBothMenus = () => {
    handleCloseNavMenu();
    handleClosePracticeMenu();
  };
  React.useEffect(() => {
    const handleResize = () => {
      closeBothMenus();
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigateToHowItWorks = () => {
    navigate("/", { state: { scrollTo: "how-it-works" } });
    handleCloseNavMenu();
  };
  const navigateToPricing = () => {
    navigate("/", { state: { scrollTo: "pricing" } });
    handleCloseNavMenu();
  };
  const buttonStyle = {
    textTransform: "none",
    textDecoration: "none",
    marginLeft: 4,
    padding: "4px 0",
    color: "#000000",
    transition: "0.5s",
    "&:hover": {
      color: "#cdd1d1"
    },
    "&::after": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: 3,
      top: "100%",
      left: 0,
      backgroundColor: "#A762F6", // Change the underline color
      transform: "scaleX(0)",
      transformOrigin: "right",
      transition: "transform 0.5s"
    },
    "&:hover::after": {
      transform: "scaleX(1)",
      transformOrigin: "left"
    }
  };
  const smallButtonStyle = {
    textTransform: "none",
    textDecoration: "none",
    color: "#000000",
    transition: "0.5s",
    "&:hover": {
      color: "#cdd1d1"
    },
    "&::after": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: 3,
      top: "100%",
      left: 0,
      backgroundColor: "#A762F6", // Change the underline color
      transform: "scaleX(0)",
      transformOrigin: "right",
      transition: "transform 0.5s"
    },
    "&:hover::after": {
      transform: "scaleX(1)",
      transformOrigin: "left"
    }
  };
  const menuItemTextStyle = {
    fontFamily: "'Inter', sans-serif",
    fontWeight: 500,
    fontSize: "18px",
    fontStyle: "normal",
    lineHeight: "24px"
  };
  return (
    <AppBar
      position="static"
      style={{
        background: "none", // Adjust the background color
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.0)" // Add a subtle box-shadow
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              marginRight: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "inter",
              fontSize: 16,
              fontWeight: 700,
              color: "#353839",
              textAlign: "left",
              textDecoration: "none",
              transition: "color 0.3s",
              "&:hover": {
                color: "#8B5CF6" // Set text color to purple when hovered
              },
              lineHeight: "1" // Decrease line-height to reduce space between words
            }}
          >
            interview
            <br /> lab
            <br /> co
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" }
            }}
          >
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="black"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" }
              }}
            >
              <Box>
                <MenuItem
                  sx={smallButtonStyle}
                  onClick={handleOpenPracticeMenu}
                >
                  <Typography sx={menuItemTextStyle}>Practice</Typography>
                </MenuItem>
                <Menu
                  id="practice-menu"
                  anchorEl={anchorElNavPractice}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  open={Boolean(anchorElNavPractice)}
                  onClose={handleClosePracticeMenu}
                  sx={{
                    display: { xs: "block", md: "none" }
                  }}
                >
                  <MenuItem onClick={handleClosePracticeMenu}>
                    <ArrowLeftIcon sx={{ color: "#A762F6" }} />
                    <Typography
                      sx={{
                        ...menuItemTextStyle,
                        color: "#A762F6"
                      }}
                      textAlign="center"
                    >
                      Back
                    </Typography>
                  </MenuItem>
                  {pages.map(page => (
                    <MenuItem
                      key={page.name}
                      component={Link}
                      to={page.link}
                      sx={smallButtonStyle}
                      onClick={() => {
                        handleClosePracticeMenu();
                        handleCloseNavMenu();
                      }}
                    >
                      <Typography sx={menuItemTextStyle}>
                        {page.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>

              <MenuItem sx={smallButtonStyle} onClick={navigateToHowItWorks}>
                <Typography sx={menuItemTextStyle}>How It Works</Typography>
              </MenuItem>

              <MenuItem sx={smallButtonStyle} onClick={navigateToPricing}>
                <Typography sx={menuItemTextStyle}>Pricing</Typography>
              </MenuItem>
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 6,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "inter",
              fontSize: 16,
              fontWeight: 700,
              textDecoration: "none",
              textAlign: "left",
              color: "#353839",
              transition: "color 0.3s",
              paddingTop: 1,
              paddingBottom: 1,
              "&:hover": {
                color: "#8B5CF6" // Set text color to purple when hovered
              },
              lineHeight: 1
            }}
          >
            interview
            <br />
            lab
            <br />
            co
          </Typography>

          <Box
            sx={{
              flexGrow: 2,
              display: { xs: "none", md: "flex" },
              marginLeft: 8
            }}
          >
            <IconButton
              aria-controls="menu-appbar"
              aria-haspopup="true"
              disableRipple
              onClick={handleOpenPracticeMenu}
              color="black"
              sx={buttonStyle}
            >
              <Typography sx={menuItemTextStyle}>Practice</Typography>
              <ArrowDropDownIcon />
            </IconButton>
            <Menu
              id="practice-menu"
              anchorEl={anchorElNavPractice}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              open={Boolean(anchorElNavPractice)}
              onClose={handleClosePracticeMenu}
              sx={{
                display: { xs: "none", md: "block" }
              }}
            >
              {pages.map(page => (
                <MenuItem
                  key={page.name}
                  component={Link}
                  sx={{ ...smallButtonStyle, padding: "12px" }}
                  to={page.link}
                  onClick={handleClosePracticeMenu}
                >
                  <Typography sx={menuItemTextStyle}>{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <Button
              disableRipple
              sx={buttonStyle}
              onClick={navigateToHowItWorks}
            >
              <Typography sx={menuItemTextStyle}>How It Works</Typography>
            </Button>
            <Button disableRipple sx={buttonStyle} onClick={navigateToPricing}>
              <Typography sx={menuItemTextStyle}>Pricing</Typography>
            </Button>
          </Box>
          <IconButton
            size="large"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="#353839"
            component={Link}
            to="/profile"
          >
            <PersonOutline />
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default MenuBar;
