import React, { useState, useContext } from "react";
import {
  PaymentElement,
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { Container, Box, Button } from "@mui/material";
import { API, graphqlOperation } from "aws-amplify";
import { updateUser } from "../graphql/mutations";
import UserContext from "./UserContext";

const CheckoutForm = props => {
  const options = {
    paymentMethodOrder: ["card", "applePay"],
    business: { name: "Interview IO" }
  };
  const { setSubscriptionType, user } = useContext(UserContext);
  const stripe = useStripe();
  const elements = useElements();
  const [isPaymentElementReady, setIsPaymentElementReady] = useState(false); // added state variable
  const onSuccess = async (subscriptionID, clientID) => {
    try {
      await API.graphql(
        graphqlOperation(updateUser, {
          input: {
            id: user.username,
            stripeSubscriptionID: subscriptionID,
            stripeUserID: clientID,
            subscriptionType: "PREMIUM"
          }
        })
      );
      setSubscriptionType("PREMIUM");
      console.log("User updated with subscription and customer IDs");
    } catch (error) {
      console.log("Error updating the user:", error);
    }
  };
  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {}
      });

      if (error) {
        console.log(error);
      } else if (paymentIntent) {
        //update user to premium user here
        onSuccess(props.subscriptionId, props.customerId);
        console.log(paymentIntent);
        props.handleClose(true);
      }
    } catch (err) {
      console.error("Error confirming payment:", err);
      props.handleClose(false);
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {/* ... other form elements */}
        <PaymentElement
          options={options}
          onReady={() => setIsPaymentElementReady(true)}
        />{" "}
        {/* added onReady event */}
        {isPaymentElementReady && (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{ margin: 2 }}
            disabled={!stripe}
          >
            Upgrade
          </Button>
        )}{" "}
        {/* added condition */}
      </form>
    </Container>
  );
};

export default CheckoutForm;
