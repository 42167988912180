import React, { useState, useEffect, useContext } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import {
  TextField,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box
} from "@mui/material";
import { Storage, API, graphqlOperation, Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import CircularProgress from "@mui/material/CircularProgress";
import { getUser } from "../graphql/queries";
import {
  createDailyAnsweredQuestions,
  updateDailyAnsweredQuestions,
  updateUser
} from "../graphql/mutations";
import { listDailyAnsweredQuestions } from "../graphql/queries";
import Feedback from "./Feedback";
import UserContext from "./UserContext";
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const AudioRecorder = ({
  question,
  conversation,
  onConversationTextChange,
  blobURL,
  audioKey,
  responseText,
  feedback,
  onBlobURLChange,
  onAudioKeyChange,
  onResponseTextChange,
  onFeedbackChange
}) => {
  const navigate = useNavigate();
  const [isRecording, setIsRecording] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const [limitReached, setLimitReached] = useState(false);
  const [transcribed, setTranscribed] = useState(false);
  const { user } = useContext(UserContext);
  const startRecording = () => {
    if (isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()

        .then(() => {
          setIsRecording(true);
          console.log("recording");
        })
        .catch(e => console.error(e));
    }
  };
  const stopRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File([blob], "recording.mp3", { type: "audio/mp3" });
        onBlobURLChange(URL.createObjectURL(file));
        setIsRecording(false);
        //a fix for now
        const key = `recording_${Date.now()}.mp3`;
        Storage.put(key, file, {
          contentType: "audio/mp3"
        }).then(data => {
          onAudioKeyChange(data.key);
          console.log("data key" + data.key);
        });
      })
      .catch(e => console.log(e));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    setTranscribed(true);
    console.log("audio Key in handle submit" + audioKey);
    await API.post("chatEndpoints", "/chat/recording", {
      body: { audioKey },
      headers: { "Content-Type": "application/json" }
    })
      .then(response => {
        onResponseTextChange(response.text.text);
      })
      .catch(error => {
        console.error("Error:", error);
      })
      .finally(() => setIsSubmitting(false));
  };
  const updateDailyAnsweredQuestionsData = async (userId, date) => {
    try {
      const response = await API.graphql(
        graphqlOperation(listDailyAnsweredQuestions, {
          filter: {
            userID: { eq: userId },
            date: { eq: date }
          }
        })
      );

      if (response.data.listDailyAnsweredQuestions.items.length > 0) {
        const item = response.data.listDailyAnsweredQuestions.items[0];
        await API.graphql(
          graphqlOperation(updateDailyAnsweredQuestions, {
            input: {
              id: item.id,
              answeredQuestionsCount: item.answeredQuestionsCount + 1
            }
          })
        );
      } else {
        await API.graphql(
          graphqlOperation(createDailyAnsweredQuestions, {
            input: {
              date: date,
              answeredQuestionsCount: 1,
              userID: userId
            }
          })
        );
      }
    } catch (error) {
      console.error("Error updating daily answered questions:", error);
    }
  };

  //TODO: change how the username is retrieved
  async function updateDailyQueries() {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      const username = userData.username;
      // Query the user from the database
      const userResponse = await API.graphql(
        graphqlOperation(getUser, { id: username })
      );
      const user = userResponse.data.getUser;
      console.log(user.subscriptionType);
      if (user.subscriptionType === "PREMIUM") return false;
      // Check if the user is a free user and has dailyQueries less than 3
      //TODO add this once rate limiting && user.dailyQueries < 3
      if (user.subscriptionType === "FREE") {
        // Increment dailyQueries by 1
        const newDailyQueries = user.dailyQueries + 1;

        // Update the user in the database with the new dailyQueries value
        await API.graphql(
          graphqlOperation(updateUser, {
            input: {
              id: username,
              dailyQueries: newDailyQueries
            }
          })
        );
        console.log("dailyQueries updated:", newDailyQueries);
        return false;
      } else {
        console.log("User not eligible for updating dailyQueries.");
        return true;
      }
    } catch (error) {
      console.error("Error updating dailyQueries:", error);
    }
  }
  const inputStyle = {
    fontFamily: "'nunito', sans-serif",
    marginBottom: 2,
    zIndex: "2",
    "& .MuiOutlinedInput-root": {
      fontSize: "1.2rem",
      fontWeight: 700,
      lineHeight: "22px",
      letterSpacing: "1px",
      borderRadius: "20px",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#3854e8"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#3854e8"
      }
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "1.2rem",
      fontWeight: 545,
      letterSpacing: "1px",

      "&.Mui-focused": {
        color: "#000000"
      }
    }
  };
  const buttonStyle = {
    fontSize: "1.1rem",
    fontWeight: 500,
    fontFamily: "'Inter', sans-serif",
    padding: "0.7rem",
    lineHeight: "22px",
    paddingLeft: "1rem",
    textTransform: "none",
    paddingRight: "1rem",
    letterSpacing: "1px",
    color: "#FFFFFF",
    borderColor: "#3854e8",
    backgroundColor: "#3854e8",
    marginBottom: 2,
    borderRadius: "20px",
    zIndex: "2",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: "#5067cf"
    }
  };
  // Call the function with the username
  const handleSubmitResponse = async e => {
    e.preventDefault();
    setIsCompleting(true);
    //TODO: Modify rate limiting
    /*
    if (await updateDailyQueries()) {
      setLimitReached(true);
      setIsCompleting(false);
      console.log("Limit reached");
      return;
    }
    */
    const currentDate = new Date();
    const dateString = currentDate.toISOString().split("T")[0];
    user && (await updateDailyAnsweredQuestionsData(user.username, dateString));
    let questionResponse = `Task: Act as an interviewing assistant and provide informative and constructive feedback, on the following response to the interview question:      
    Question: ${question}    
    Response: "${responseText}"
    Topic: Interviewing Feedback
    Style: Business
    Tone: Professional
    Audience: Business audience
    Please format your feedback in the following JSON format:
    
    {
      "feedback": {
        "positive": [
          // In-depth list of things done well in the response
        ],
        "constructive": [
          // In-depth list of things that were not done well in the response
        ],
        "improvement": [
          // In-depth list of ways that the interviewee can improve their response
        ]
      }
    }
    
    If there is nothing to say about a particular field, the array must be left empty. Please make sure to use the exact field names provided (positive, constructive, and improvement), and include double quotes around all strings. Do not include any additional text in your response.
    `;
    //TODO: Fix Conversation storage
    const newConversation = { role: "user", content: questionResponse };
    console.log(conversation);
    try {
      await API.post("chatEndpoints", "/chat/completion", {
        body: [newConversation], // replace this with attributes you need
        headers: { "Content-Type": "application/json" }
      }).then(res => {
        //set the conversation state to the array of the current conversation
        const response = res;
        console.log(response);
        const result = res.query.content;
        // Call onConversationTextChange with both newConversation and res.query
        onConversationTextChange([newConversation, res.query]);
        const cleanedResult = result.startsWith("\n\n")
          ? result.substring(2)
          : result;

        const json = JSON.parse(cleanedResult);
        onFeedbackChange(JSON.stringify(json.feedback));
      });
    } catch (error) {
      console.error(error);
      onResponseTextChange("An error occurred. Please try again later.");
    } finally {
      setIsCompleting(false);
    }
  };

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        console.log("Permission Granted");
        setIsBlocked(false);
      })
      .catch(() => {
        console.log("Permission Denied");
        setIsBlocked(true);
      });
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px"
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={startRecording}
          disabled={isRecording}
          sx={{ fontWeight: 700, marginRight: "10px" }}
        >
          <MicIcon />
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={stopRecording}
          disabled={!isRecording}
          sx={{ fontWeight: 700, marginRight: "10px" }}
        >
          <MicOffIcon />
        </Button>
      </div>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
      >
        {blobURL && (
          <audio src={blobURL} controls="controls" style={{ flexGrow: 1 }} />
        )}
        {!blobURL && (
          <audio src={null} controls="controls" style={{ flexGrow: 1 }} />
        )}
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          disabled={!audioKey}
          onClick={handleSubmit}
          sx={buttonStyle}
        >
          Transcribe
        </Button>
      </div>
      {isSubmitting ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      ) : responseText || transcribed ? (
        <div>
          <TextField
            value={responseText}
            multiline
            onChange={e => onResponseTextChange(e.target.value)}
            placeholder="Your response will be displayed here"
            style={{ width: "100%", marginBottom: "20px" }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitResponse}
            sx={buttonStyle}
          >
            Submit For Analysis
          </Button>
        </div>
      ) : (
        <div />
      )}
      {isCompleting ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      ) : feedback ? (
        <div>
          <Feedback feedback={feedback} />
        </div>
      ) : (
        <div />
      )}
      <Dialog open={limitReached} onClose={() => setLimitReached(false)}>
        <DialogTitle>{"Daily Limit Reached"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have reached your daily limit. To get unlimited attempts,
            upgrade to a premium membership.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLimitReached(false)} color="primary">
            Close
          </Button>
          <Button
            onClick={() => {
              /* Redirect to upgrade membership page */
              navigate("/upgrade");
            }}
            color="primary"
          >
            Upgrade Membership
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AudioRecorder;
