import React from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  LinearProgress
} from "@mui/material";

const ProgressStatistics = ({ totalQuestions, questionsToday }) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center"
      }}
    >
      <Grid item xs={12} sm={12} md={6}>
        <Card
          sx={{
            minWidth: 100,
            textAlign: "center",
            mb: "1rem",
            borderRadius: "10px",
            boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
            padding: "2rem",
            margin: 2
          }}
        >
          <CardContent>
            <Typography
              sx={{
                display: "flex",
                color: "#A762F6",
                justifyContent: "center",
                fontFamily:
                  "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
                fontWeight: 500
              }}
              variant="h6"
            >
              Total Questions Completed
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                fontFamily:
                  "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
                color: "#425466",
                marginBottom: 2
              }}
              variant="h6"
            >
              {totalQuestions}
            </Typography>
            <LinearProgress
              sx={{ mt: "1rem" }}
              variant="determinate"
              value={(totalQuestions / 100) * 100}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Card
          sx={{
            minWidth: 100,
            textAlign: "center",
            mb: "1rem",
            borderRadius: "10px",
            boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
            padding: "2rem",
            margin: 2
          }}
        >
          <CardContent>
            <Typography
              sx={{
                display: "flex",
                color: "#A762F6",
                justifyContent: "center",
                fontFamily:
                  "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
                fontWeight: 500
              }}
              variant="h6"
            >
              Questions Practiced Today
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                fontFamily:
                  "'sohne-var', 'Helvetica Neue', 'Arial', sans-serif",
                color: "#425466",
                marginBottom: 2
              }}
              variant="h6"
            >
              {questionsToday}
            </Typography>
            <LinearProgress
              sx={{ mt: "1rem", color: "#96f" }}
              variant="determinate"
              value={questionsToday}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProgressStatistics;
