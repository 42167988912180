import React, { useState, useContext } from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress, // Add this line
  Backdrop
} from "@mui/material";
import UserContext from "./UserContext";
import { useNavigate } from "react-router-dom";
import { createUser } from "../graphql/mutations";
export default function LogIn() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const { setUser } = useContext(UserContext);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [usernameExistsDialogOpen, setUsernameExistsDialogOpen] = useState(
    false
  );
  const [signUpError, setSignUpError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSignIn, setLoadingSignIn] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [codeMismatchDialogOpen, setCodeMismatchDialogOpen] = useState(false);

  const toggleSignUp = () => {
    setIsSignUp(!isSignUp);
  };
  const navigate = useNavigate();

  async function signUp() {
    console.log("In sign up");
    if (username && password && email && name) {
      const lowerCaseUsername = username.toLowerCase(); // Add this line
      console.log(lowerCaseUsername);
      try {
        const { user } = await Auth.signUp({
          username: lowerCaseUsername,
          password,
          attributes: {
            email,
            name: name
          },
          autoSignIn: {
            enabled: true
          }
        });
        setUser(user);
        console.log(user);
        setConfirmationDialogOpen(true);
      } catch (error) {
        if (error.code === "UsernameExistsException") {
          // Handle the specific exception here
          console.log("Username already exists:", error);
          setUsernameExistsDialogOpen(true);
        } else {
          // Let all other exceptions fall to the next catch statement
          console.log("error signing up:", error);
          setSignUpError(true);
        }
      }
    } else {
      console.log("Not all fields filled");
    }
  }
  async function resendConfirmationCode() {
    try {
      await Auth.resendSignUp(username);
      console.log("code resent successfully");
    } catch (err) {
      console.log("error resending code: ", err);
    }
  }
  async function confirmSignUp() {
    try {
      setLoading(true); // Add this line
      const lowerCaseUsername = username.toLowerCase(); // Add this line
      console.log("In confirm sign up");
      console.log(username);
      await Auth.confirmSignUp(lowerCaseUsername, confirmationCode);
      await Auth.signIn(lowerCaseUsername, password).then(user =>
        setUser(user)
      ); // Add this line
      await API.graphql(
        graphqlOperation(createUser, {
          input: {
            id: lowerCaseUsername,
            username: lowerCaseUsername,
            name: name,
            dailyQueries: 0,
            subscriptionType: "FREE"
          }
        })
      ).then(() => {
        setConfirmationDialogOpen(false);
        navigate("/profile");
      });
    } catch (error) {
      if (error.code === "CodeMismatchException") {
        console.log("did error");
        setCodeMismatchDialogOpen(true);
      }

      console.log("error confirming sign up", error.code);
    } finally {
      setLoading(false); // Add this line
    }
  }

  async function signIn() {
    try {
      setLoadingSignIn(true);
      const lowerCaseUsername = username.toLowerCase();
      const user = await Auth.signIn(lowerCaseUsername, password);
      setUser(user);
      navigate("/profile"); // Move this line inside the try block
    } catch (error) {
      console.log("error signing in", error);
    } finally {
      setLoadingSignIn(false);
    }
  }

  async function handleSocialSignIn() {
    await Auth.federatedSignIn({ provider: "Google" });
    /*
    await API.graphql(
      graphqlOperation(createUser, {
        input: {
          id: username,
          username: username,
          name: name,
          dailyQueries: 0,
          subscriptionType: "FREE"
        }
      })
    );
    */
  }
  const signUpButton = (
    <Grid item xs={8}>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        sx={{
          borderRadius: "20px",
          background:
            "linear-gradient(45deg, #F57DC6 20%, #7B9FE4 60%, #A762F6 90%)",
          color: "white"
        }}
        onClick={isSignUp ? signUp : toggleSignUp}
      >
        Sign Up
      </Button>
    </Grid>
  );
  const loginButton = (
    <Grid item xs={8}>
      <Button
        variant="contained"
        fullWidth
        onClick={!isSignUp ? signIn : toggleSignUp}
        sx={{
          borderRadius: "20px",
          background:
            "linear-gradient(45deg, #7B9FE4 30%, #A762F6 60%, #F57DC6 90%)",
          color: "white"
        }}
      >
        LogIn
      </Button>
    </Grid>
  );
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",

          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Box
          sx={{
            p: 4,
            mt: 8,
            borderRadius: 2,
            backgroundColor: "white",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)"
          }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "1.7rem",
              marginBottom: 4,
              letterSpacing: 1
            }}
            gutterBottom
            variant="h2"
          >
            {isSignUp ? "Sign Up" : "Log In"}
          </Typography>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "center" }}
            spacing={2}
          >
            <Grid item xs={8}>
              <TextField
                required
                fullWidth
                variant="standard"
                label="Username"
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                required
                fullWidth
                variant="standard"
                type="password"
                label="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </Grid>
            {isSignUp && (
              <>
                <Grid item xs={8}>
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    label="Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    label="Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </Grid>
              </>
            )}

            {!isSignUp ? loginButton : signUpButton}
            <Grid item xs={12}>
              <Typography sx={{ paddingTop: 3 }} variant="subtitle1">
                Or continue with
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "100%",
                  backgroundColor: "#fff",
                  margin: "5px",
                  "&:hover": {
                    backgroundColor: "#fff" // Keep the background color the same on hover
                  }
                }}
                onClick={() => handleSocialSignIn()}
              >
                <img src="https://colorlib.com/etc/lf/Login_v9/images/icons/icon-google.png" />
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ paddingTop: 6 }} variant="subtitle1">
                Or
              </Typography>
            </Grid>
            {isSignUp ? loginButton : signUpButton}
          </Grid>
        </Box>

        <Dialog
          open={confirmationDialogOpen}
          onClose={() => setConfirmationDialogOpen(false)}
        >
          <DialogTitle>Confirm Sign Up</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter the confirmation code sent to your email.
              <Button color="primary" onClick={resendConfirmationCode}>
                Resend Code
              </Button>
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Confirmation Code"
              fullWidth
              value={confirmationCode}
              onChange={e => setConfirmationCode(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmationDialogOpen(false)}>
              Cancel
            </Button>
            <Button type="button" onClick={() => confirmSignUp()}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={usernameExistsDialogOpen}
          onClose={() => setUsernameExistsDialogOpen(false)}
        >
          <DialogTitle>Username Exists</DialogTitle>
          <DialogContent>
            <DialogContentText>
              The username you entered already exists. Please try another one.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setUsernameExistsDialogOpen(false)}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={signUpError} onClose={() => setSignUpError(false)}>
          <DialogTitle>Error Creating an Account</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Make sure that you have properly entered your credentials.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSignUpError(false)}>OK</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={codeMismatchDialogOpen}
          onClose={() => setCodeMismatchDialogOpen(false)}
        >
          <DialogTitle>Code Mismatch</DialogTitle>
          <DialogContent>
            <DialogContentText>
              The confirmation code you entered is incorrect. Please try again.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCodeMismatchDialogOpen(false)}>OK</Button>
          </DialogActions>
        </Dialog>
        <Backdrop
          open={loadingSignIn || loading}
          sx={{
            zIndex: theme => theme.zIndex.drawer + 1,
            color: "#fff"
          }}
        >
          <CircularProgress />
        </Backdrop>
      </Box>
    </Container>
  );
}
