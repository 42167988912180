/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      name
      subscriptionType
      stripeSubscriptionID
      stripeUserID
      dailyQueries
      questions {
        items {
          id
          questionText
          qID
          feedback
          responseText
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyAnsweredQuestions {
        items {
          id
          date
          answeredQuestionsCount
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      name
      subscriptionType
      stripeSubscriptionID
      stripeUserID
      dailyQueries
      questions {
        items {
          id
          questionText
          qID
          feedback
          responseText
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyAnsweredQuestions {
        items {
          id
          date
          answeredQuestionsCount
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      name
      subscriptionType
      stripeSubscriptionID
      stripeUserID
      dailyQueries
      questions {
        items {
          id
          questionText
          qID
          feedback
          responseText
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyAnsweredQuestions {
        items {
          id
          date
          answeredQuestionsCount
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      questionText
      qID
      feedback
      responseText
      conversation {
        role
        content
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      questionText
      qID
      feedback
      responseText
      conversation {
        role
        content
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      questionText
      qID
      feedback
      responseText
      conversation {
        role
        content
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDailyAnsweredQuestions = /* GraphQL */ `
  mutation CreateDailyAnsweredQuestions(
    $input: CreateDailyAnsweredQuestionsInput!
    $condition: ModelDailyAnsweredQuestionsConditionInput
  ) {
    createDailyAnsweredQuestions(input: $input, condition: $condition) {
      id
      date
      answeredQuestionsCount
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateDailyAnsweredQuestions = /* GraphQL */ `
  mutation UpdateDailyAnsweredQuestions(
    $input: UpdateDailyAnsweredQuestionsInput!
    $condition: ModelDailyAnsweredQuestionsConditionInput
  ) {
    updateDailyAnsweredQuestions(input: $input, condition: $condition) {
      id
      date
      answeredQuestionsCount
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteDailyAnsweredQuestions = /* GraphQL */ `
  mutation DeleteDailyAnsweredQuestions(
    $input: DeleteDailyAnsweredQuestionsInput!
    $condition: ModelDailyAnsweredQuestionsConditionInput
  ) {
    deleteDailyAnsweredQuestions(input: $input, condition: $condition) {
      id
      date
      answeredQuestionsCount
      userID
      createdAt
      updatedAt
    }
  }
`;
